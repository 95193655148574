<template>
  <div class="image-selector">
    <div class="image-container" ref="imageContainer" :style="containerStyle">
      <img :src="imageSrc" alt="Image" @load="onImageLoad" :style="imageStyle" draggable="false" />
      <div class="overlay" :style="overlayStyle"></div>
      <div v-if="selectionBox && hasInitialSelection" class="selection-box" :style="selectionBoxStyle"
        @mousedown="startDragBox($event)"></div>
      <!-- 四个角的拖拽点 -->
      <div v-if="selectionBox && showCorners && hasInitialSelection" v-for="(corner, index) in corners" :key="index"
        class="corner" :style="getCornerStyle(corner)" :data-index="index" @mousedown="startDragCorner(index, $event)">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
      required: true,
    },
    containerSize: {
      type: Number,
      required: true,
    },
    draggable: {
      type: Boolean,
      default: true, // 默认允许拖动
    },
    showCorners: {
      type: Boolean,
      default: true, // 默认展示四个角
    },
    initialSelection: {
      type: Object,
      default: null, // 默认不传入
    },
  },
  data() {
    return {
      imageContainer: null,
      imageDimensions: { width: 0, height: 0 },
      selectionBox: null,
      draggingType: null,
      startDragPosition: { x: 0, y: 0 },
      currentTranslate: { x: 0, y: 0 },
      draggingCornerIndex: null,
      isDragging: false,
      imageRatio: 0,
    };
  },
  computed: {
    // 判断是否有初始选择框
    hasInitialSelection() {
      return this.initialSelection !== null && typeof this.initialSelection === 'object';
    },
    // 容器样式
    containerStyle() {
      return {
        width: `${this.containerSize}px`,
        height: `${this.containerSize}px`,
      };
    },
    // 图片样式
    imageStyle() {
      return {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        userSelect: 'none', // 禁止用户选中图片
        pointerEvents: 'none', // 禁止图片触发鼠标事件
      };
    },
    // 图片的实际显示区域
    imageDisplayArea() {
      const { width: imgWidth, height: imgHeight } = this.imageDimensions;
      const containerRatio = this.containerSize / this.containerSize; // 1:1
      this.imageRatio = imgWidth / imgHeight;

      let displayWidth, displayHeight, offsetX, offsetY;

      if (this.imageRatio > containerRatio) {
        // 图片宽度占满容器
        displayWidth = this.containerSize;
        displayHeight = this.containerSize / this.imageRatio;
        offsetX = 0;
        offsetY = (this.containerSize - displayHeight) / 2;
      } else {
        // 图片高度占满容器
        displayHeight = this.containerSize;
        displayWidth = this.containerSize * this.imageRatio;
        offsetX = (this.containerSize - displayWidth) / 2;
        offsetY = 0;
      }

      return {
        width: displayWidth,
        height: displayHeight,
        offsetX,
        offsetY,
        imgWidth, // 原图宽度
        imgHeight, // 原图高度
      };
    },
    // 四个角的坐标
    corners() {
      if (!this.selectionBox) return [];
      const { left, top, width, height } = this.selectionBox;
      return [
        { x: left, y: top }, // 左上角
        { x: left + width, y: top }, // 右上角
        { x: left + width, y: top + height }, // 右下角
        { x: left, y: top + height }, // 左下角
      ];
    },
    // 遮罩层的样式
    overlayStyle() {
      if (!this.selectionBox) return {};
      const { left, top, width, height } = this.selectionBox;
      return {
        clipPath: `polygon(
          0% 0%,
          0% 100%,
          ${left}px 100%,
          ${left}px ${top}px,
          ${left + width}px ${top}px,
          ${left + width}px ${top + height}px,
          ${left}px ${top + height}px,
          ${left}px 100%,
          100% 100%,
          100% 0%
        )`,
      };
    },
    // 选择框的样式
    selectionBoxStyle() {
      if (!this.selectionBox) return {};
      const { left, top, width, height } = this.selectionBox;
      return {
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${this.currentTranslate.x}px, ${this.currentTranslate.y}px)`,
      };
    },
  },
  watch: {
    // 监听 initialSelection 的变化
    initialSelection() {
      if (this.imageDimensions.width && this.imageDimensions.height) {
        if (this.hasInitialSelection) {
          this.initializeSelectionBox();
        } else {
          this.selectionBox = null; // 如果没有初始选择框，则清空选择框
        }
      }
    },
  },
  methods: {
    // 图片加载完成后初始化选择框
    onImageLoad(event) {
      const img = event.target;
      this.imageDimensions = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };

      // 如果有初始选择框，则初始化
      if (this.hasInitialSelection) {
        this.initializeSelectionBox();
      }
    },
    // 初始化选择框
    initializeSelectionBox() {
      const { offsetX, offsetY, width: displayWidth, height: displayHeight, imgWidth, imgHeight } = this.imageDisplayArea;

      // 计算比例（显示区域大小 / 原图大小）
      const scaleX = displayWidth / imgWidth;
      const scaleY = displayHeight / imgHeight;

      // 校验并限制 initialSelection 的坐标
      const clampedSelection = {
        left: Math.max(0, Math.min(this.initialSelection.left, imgWidth)),
        right: Math.max(0, Math.min(this.initialSelection.right, imgWidth)),
        top: Math.max(0, Math.min(this.initialSelection.top, imgHeight)),
        bottom: Math.max(0, Math.min(this.initialSelection.bottom, imgHeight)),
      };

      // 将传入的 initialSelection 转换为显示区域坐标
      let left = clampedSelection.left * scaleX + offsetX;
      let top = clampedSelection.top * scaleY + offsetY;

      // 修正 width 的计算
      let width = (clampedSelection.right - clampedSelection.left) * scaleX;
      let height = (clampedSelection.bottom - clampedSelection.top) * scaleY;

      // 初始化选择框
      this.selectionBox = {
        left,
        top,
        width,
        height,
      };

      // 确保选择框在图片显示区域内
      this.clampSelectionBox();
    },
    // 限制选择框的位置和大小在图片范围内
    clampSelectionBox() {
      if (!this.selectionBox) return;

      const { offsetX, offsetY, width: displayWidth, height: displayHeight } = this.imageDisplayArea;
      const { left, top, width, height } = this.selectionBox;

      // 限制选择框的 left 和 top 不小于图片的偏移量
      this.selectionBox.left = Math.max(offsetX, left);
      this.selectionBox.top = Math.max(offsetY, top);

      // 限制选择框的 right 和 bottom 不超过图片的显示区域
      const maxRight = offsetX + displayWidth;
      const maxBottom = offsetY + displayHeight;

      // 计算选择框的 right 和 bottom
      const right = this.selectionBox.left + width;
      const bottom = this.selectionBox.top + height;

      // 如果选择框的 right 或 bottom 超出图片显示区域，调整 left 或 top
      if (right > maxRight) {
        this.selectionBox.left = maxRight - width;
      }
      if (bottom > maxBottom) {
        this.selectionBox.top = maxBottom - height;
      }

      // 限制选择框的最小大小
      this.selectionBox.width = Math.max(10, width);
      this.selectionBox.height = Math.max(10, height);
    },
    // 获取角的样式
    getCornerStyle(corner) {
      return {
        left: `${corner.x}px`,
        top: `${corner.y}px`,
      };
    },
    // 开始拖动选择框
    startDragBox(event) {
      if (!this.draggable) return; // 如果不可拖动，直接返回
      this.draggingType = 'box';
      this.startDragPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    // 开始拖动角
    startDragCorner(index, event) {
      if (!this.draggable) return; // 如果不可拖动，直接返回
      this.draggingType = 'corner';
      this.draggingCornerIndex = index;
      this.startDragPosition = {
        x: event.clientX,
        y: event.clientY,
      };
      document.addEventListener('mousemove', this.onDrag);
      document.addEventListener('mouseup', this.stopDrag);
    },
    // 拖动过程中更新选择框
    onDrag(event) {
      if (!this.isDragging) {
        this.isDragging = true;
        requestAnimationFrame(() => {
          if (this.draggingType === 'box') {
            // 拖动整个选择框
            const deltaX = event.clientX - this.startDragPosition.x;
            const deltaY = event.clientY - this.startDragPosition.y;
            this.currentTranslate = {
              x: deltaX,
              y: deltaY,
            };

            // 更新选择框的位置
            this.selectionBox.left += deltaX;
            this.selectionBox.top += deltaY;

            // 限制选择框的位置在图片范围内
            this.clampSelectionBox();

            // 更新起始拖动位置
            this.startDragPosition = {
              x: event.clientX,
              y: event.clientY,
            };
          } else if (this.draggingType === 'corner') {
            // 拖动角调整大小
            const deltaX = event.clientX - this.startDragPosition.x;
            const deltaY = event.clientY - this.startDragPosition.y;
            const { left, top, width, height } = this.selectionBox;
            const { offsetX, offsetY, width: displayWidth, height: displayHeight } = this.imageDisplayArea;

            // 根据角点类型计算新的位置和大小
            switch (this.draggingCornerIndex) {
              case 0: // 左上角
                this.selectionBox.left = Math.max(offsetX, Math.min(left + deltaX, left + width - 10));
                this.selectionBox.top = Math.max(offsetY, Math.min(top + deltaY, top + height - 10));
                this.selectionBox.width = Math.max(10, width - deltaX);
                this.selectionBox.height = Math.max(10, height - deltaY);
                break;
              case 1: // 右上角
                this.selectionBox.top = Math.max(offsetY, Math.min(top + deltaY, top + height - 10));
                this.selectionBox.width = Math.max(10, Math.min(displayWidth - (this.selectionBox.left - offsetX), width + deltaX));
                this.selectionBox.height = Math.max(10, height - deltaY);
                break;
              case 2: // 右下角
                this.selectionBox.width = Math.max(10, Math.min(displayWidth - (this.selectionBox.left - offsetX), width + deltaX));
                this.selectionBox.height = Math.max(10, Math.min(displayHeight - (this.selectionBox.top - offsetY), height + deltaY));
                break;
              case 3: // 左下角
                this.selectionBox.left = Math.max(offsetX, Math.min(left + deltaX, left + width - 10));
                this.selectionBox.width = Math.max(10, width - deltaX);
                this.selectionBox.height = Math.max(10, Math.min(displayHeight - (this.selectionBox.top - offsetY), height + deltaY));
                break;
            }

            // 更新起始拖动位置
            this.startDragPosition = {
              x: event.clientX,
              y: event.clientY,
            };
          }
          this.isDragging = false;
        });
      }
    },
    // 停止拖动
    stopDrag() {
      if (this.draggingType === 'box') {
        // 更新选择框的位置
        this.selectionBox.left += this.currentTranslate.x;
        this.selectionBox.top += this.currentTranslate.y;
        this.currentTranslate = { x: 0, y: 0 };

        // 限制选择框的位置在图片范围内
        this.clampSelectionBox();
      }

      // 重置拖动状态
      this.draggingType = null;
      this.draggingCornerIndex = null;

      // 移除事件监听器
      document.removeEventListener('mousemove', this.onDrag);
      document.removeEventListener('mouseup', this.stopDrag);

      // 获取图片的实际显示区域
      const { offsetX, offsetY, width: displayWidth, height: displayHeight } = this.imageDisplayArea;

      // 计算比例（原图大小 / 显示区域大小）
      const scaleX = this.imageDimensions.width / displayWidth;
      const scaleY = this.imageDimensions.height / displayHeight;

      // 计算选择框在原图上的位置和大小
      const { left, top, width, height } = this.selectionBox;

      // 转换为原图上的值
      const originalLeft = (left - offsetX) * scaleX; // 选择框的左边界（相对于原图）
      const originalTop = (top - offsetY) * scaleY;   // 选择框的上边界（相对于原图）
      const originalWidth = width * scaleX;           // 选择框的宽度（相对于原图）
      const originalHeight = height * scaleY;         // 选择框的高度（相对于原图）
      const originalRight = originalLeft + originalWidth; // 选择框的右边界
      const originalBottom = originalTop + originalHeight; // 选择框的下边界

      // 返回数据
      this.$emit('selection', {
        left: originalLeft,
        right: originalRight,
        top: originalTop,
        bottom: originalBottom,
        width: originalWidth,
        height: originalHeight,
      });
    },
  },
};
</script>

<style scoped>
.image-selector {
  position: relative;
  display: inline-block;
  background: #fff;
  overflow: hidden;
}

.image-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  user-select: none;
  /* 禁止用户选中图片 */
  pointer-events: none;
  /* 禁止图片触发鼠标事件 */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.selection-box {
  position: absolute;
  border: 0.5px solid #fff;
  cursor: move;
}

.corner {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: width 0.1s, height 0.1s, background-color 0.1s;
}

.corner[data-index="0"],
.corner[data-index="2"] {
  cursor: nwse-resize;
}

.corner[data-index="1"],
.corner[data-index="3"] {
  cursor: nesw-resize;
}
</style>