<template>
  <div>
    <div class="headMain">
      <div id="HomePageTop">
        <!-- 谷歌插件提示 -->
        <GooglePluginReminder v-if="$route.path != '/promoter'" />
        <div class="booosdj">
          <div class="qiTa" id="qitasss">
            <div class="zhiNan">
              <span @click="$fun.toPage('/about-us')" :class="{ isactive: $route.path == '/about-us' }">
                {{ $fanyi("关于RAKUMART") }}
              </span>
              <span @click="$fun.toPage('/user-guide')" :class="{ isactive: $route.path == '/user-guide' }">
                {{ $fanyi("用户指南") }}
              </span>
              <span @click="$fun.toPage('/faq')" :class="{ isactive: $route.path == '/faq' }">
                {{ $fanyi("常见问题解答") }}
              </span>
              <span @click="$fun.toPage('/commission')" :class="{ isactive: $route.path == '/commission' }">
                {{ $fanyi("佣金") }}
              </span>
              <span @click="$fun.toPage('/internationalfreight')"
                :class="{ isactive: $route.path == '/internationalfreight' }">
                {{ $fanyi("国际物流") }}
              </span>

              <span @click="LogisticPrice">
                {{ $fanyi("物流价格") }}
              </span>
              <span @click="$fun.toPage('/additionalservices')"
                :class="{ isactive: $route.path == '/additionalservices' }">
                {{ $fanyi("附加服务") }}
              </span>
              <!-- $fun.toPage('/tutorialvideo') -->
              <span @click="tutorialvideo" :class="{ isactive: $route.path == '/tutorialvideo' }">
                {{ $fanyi("视频介绍") }}
              </span>
              <span @click="toLeture('/promoter')" :class="{ isactive: $route.path == '/promoter' }">

                {{ $fanyi("讲师宣传") }}
              </span>
            </div>
            <div class="huiLv">
              {{ $fanyi("今日人民币与美元兑换汇率") }}:
              <span class="numd">1CNY</span>
              <span>
                ≈
              </span>
              <font class="num numd">
                {{ $fun.huilvqianf($store.state.exchangeRate) }}USD
              </font>


            </div>
            <!-- 翻译 -->
            <el-dropdown @command="clickDropdown">
              <div class="China-ukhandover">
                <span class="chainse" :class="{ active: $store.state.languagetype == 'Chinese' }">
                  中</span>
                /
                <span class="english" :class="{ active: $store.state.languagetype == 'English' }">English</span>
              </div>

              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="English">English</el-dropdown-item>
                <el-dropdown-item command="Chinese">Chinese</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <lectureHeader v-if="$route.path == '/promoter'"></lectureHeader>
        <div id="HomePageTopCon" :class="{ expanding: isExpand }" v-else>
          <div class="con_main">
            <div class="logoBox" :class="{ expanding_img: isExpand }">
              <img id="logo" @click="backHome" src="../../assets/hometop/logo-rakumart-europe.svg" alt="" />
            </div>
            <div class="searchBoxDiv" v-show="!isExpand">
              <el-popover ref="popover" popper-class="popoversearchTip" placement="bottom" trigger="focus" width="722">
                <div class="Historicalrecord" v-if="keyWordList.length > 0">
                  <div class="Historical">{{ $fanyi("历史记录") }}</div>

                  <div class="record">
                    <div class="record-item" v-for="(Historyitem, index) in keyWordList" :key="index">
                      <span @click="Historicalsearch(Historyitem)">{{
                        Historyitem
                      }}</span>
                      <i @click.stop="deleteOldKeyWord(index)" class="el-icon-close"></i>
                    </div>
                  </div>
                </div>

                <div class="searchitem" v-for="item in topKeywordlist" :key="item.id">
                  <h2>{{ item.translate_name }}</h2>
                  <div class="classification-item">
                    <span @click="Historicalsearch(childsitem.translate_name)" v-for="childsitem in item.childs"
                      :key="childsitem.id">
                      {{ childsitem.translate_name }}</span>
                  </div>
                </div>
                <el-input style="width: 598px" slot="reference" @paste.native="handlePaste" @focus="focus"
                  :readonly="readonly" v-model="$store.state.searchInfo" :placeholder="$fanyi('请输入关键词、1688商品链接或通过图片搜索')
                    " @keydown.enter.native="search" @change="$store.commit('getshoplistpage', 1)">
                </el-input>
              </el-popover>

              <el-upload ref="uploadRef" class="upImg" :http-request="httpup" list-type="picture-card"
                :before-upload="befoUpload" accept=".jpg,.jpeg,.png" action="" :show-file-list="false">

                <el-popover placement="bottom" width="200" trigger="hover">
                  <div>
                    {{ $fanyi('Ctrl+V 将图片粘贴到搜索栏中') }}
                  </div>
                  <template slot="reference">
                    <img src="../../assets/icon/upload.svg" alt="" />
                  </template>
                </el-popover>
              </el-upload>
              <!-- <div class="searchInputBoxFg"></div> -->
              <el-button @click="search">
                <img src="../../assets/icon/search.svg" />
              </el-button>
            </div>
            <div class="operate" v-show="!isExpand">
              <!-- 未登录 -->
              <div v-if="!token" class="userDetail notLogin">
                <img src="../../assets/icon/personal.svg" alt="" />
                <span @click="gologin">
                  {{ $fanyi("登录") }}
                </span>
                <span class="fg"> / </span>
                <span class="register_box">
                  <router-link to="/register">
                    {{ $fanyi("注册") }}
                  </router-link>
                </span>
              </div>
              <!-- 登陆后的user信息 -->
              <div v-else class="userDetail">
                <el-upload class="upload-demo" action="https://laravel.rakumart.us/api/common/uploadFile"
                  :show-file-list="false" :on-success="handleAvatarSuccess" name="file" accept=".jpg,.jpeg,.png"
                  :before-upload="avatarbefoUpload">
                  <div class="showDow">
                    <img src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66dab8fde5a29.svg" alt="">
                  </div>
                  <img class="userHeadImg" :src="userInfo ? userInfo.head_img : ''" alt="" />
                </el-upload>
                <div class="userLoginInfoBox">
                  <el-popover placement="top" trigger="hover">
                    <div>{{ userInfo.user_name }}</div>
                    <template slot="reference">
                      <div class="info_referce">
                        <span>Hello&nbsp;</span>
                        <span class="user_name u-line" slot="reference">
                          {{ userInfo.user_name ? userInfo.user_name : "" }}
                        </span>
                      </div>

                    </template>
                  </el-popover>

                  <p class="centerBtnBox">
                    <span class="centerBtn" @click="
                      $fun.toPage('/user/index');
                    getUser();
                    ">
                      {{ $fanyi("个人中心") }}
                    </span>
                    <span style="font-size: 12px"> / </span>
                    <span class="routerBtn" @click="loginOut">{{ $fanyi("退出登录") }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="cartBtn" ref="end">
                <button type="button" @click="$fun.toPage('/cart')">
                  <div class="carticon">
                    <img src="../../assets/icon/shopping_cart.svg" alt="" id="gouwuche" />
                    <span class="cartNum">
                      {{ goodsNum }}
                    </span>
                  </div>
                  <span :class="{ defaultCart: !token }" class="cart_name">
                    {{ $fanyi("购物车") }}
                  </span>

                </button>
              </div>
              <!-- 通知 -->
              <div class="notice_box">
                <button type="button" @click="$fun.toPage('/user/inform')">
                  <div class="carticon">
                    <img src="../../assets/user-img/new-menu/massage.svg" alt="" id="gouwuche" />
                    <span class="cartNum">
                      {{ noticeNum }}
                    </span>
                  </div>
                  <span :class="{ defaultCart: !token }" class="cart_name">
                    {{ $fanyi('新消息') }}
                  </span>

                </button>
              </div>
            </div>
          </div>
          <div class="expand_box" @click="handleExpand">
            <span>
              {{ isExpand ? $fanyi('收起') : $fanyi('展开') }}
            </span>
            <img v-show="isExpand" src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66ea8adc5b8f6.svg"
              alt="">
            <img v-show="!isExpand" src="https://rakumart-eu.oss-ap-northeast-1.aliyuncs.com/202409/66ea8af1a323e.svg"
              alt="">
          </div>
        </div>

        <sideBar />
      </div>
    </div>
  </div>
</template>

<script>
import ClassificationOfGoodsVue from "../homePage/ClassificationOfGoods.vue";
import router from "../../router";
import sideBar from "../homePage/sideBar.vue";
import GooglePluginReminder from "../../components/head/GooglePluginReminder.vue";
import lectureHeader from '../../views/footList/components/header.vue'

export default {
  data() {
    return {
      logininfo: localStorage.getItem("logininfo"),
      value: "",
      pageLoading: null,
      showQita: true, //显示其他
      readonly: true,
      visible: true,
      value: "",
      keyWordList: [], //历史记录
      topKeywordlist: [], // 配置搜索列表
      noticeNum: 0,
      isExpand: false
    };
  },
  created() {
    // 获取消息通知数量
    this.getNoticeUnreadNoticeCount()

    // 获取购物车数量
    this.$store.dispatch("goodsToCartNum");
    if (localStorage.getItem("clientUsFrontPCkeyWord") != null) {
      this.keyWordList = JSON.parse(
        localStorage.getItem("clientUsFrontPCkeyWord")
      );
    }
    // 获取顶部栏进行监听
    this.$nextTick(() => {
      if (this.$store.state.isPluginInstalled == true) {
        if (this.isExpand) {
          document.getElementsByClassName("headMain")[0].style.height = "153px";
        } else {
          document.getElementsByClassName("headMain")[0].style.height = "193px";
        }

      } else {
        if (this.isExpand) {
          document.getElementsByClassName("headMain")[0].style.height = "105px";
        } else {
          document.getElementsByClassName("headMain")[0].style.height = "145px";
        }

      }
    });
    this.gettopKeyword();

  },
  components: {
    sideBar,
    ClassificationOfGoodsVue,
    GooglePluginReminder,
    lectureHeader
  },
  computed: {
    searchInfo() {
      return this.$store.state.searchInfo;
    },
    goodsNum() {
      return this.$store.state.cartCount;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    options() {
      return this.$store.state.goodsList;
    },
    token() {
      if (this.$store.state.userInfo) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.changeHeight();
  },

  methods: {
    //点击头部的展开和收起
    handleExpand() {
      this.isExpand = !this.isExpand
      if (this.isExpand) {
        document.getElementById("HomePageTopCon").style.height = "50px";
        if (this.$store.state.isPluginInstalled == true) {
          document.getElementsByClassName("headMain")[0].style.height = "163px";
        } else {
          document.getElementsByClassName("headMain")[0].style.height = "115px";
        }
      } else {

        document.getElementById("HomePageTopCon").style.height = "80px";
        if (this.$store.state.isPluginInstalled == true) {
          document.getElementsByClassName("headMain")[0].style.height = "193px";
        } else {
          document.getElementsByClassName("headMain")[0].style.height = "145px";
        }

      }
      this.$forceUpdate()
    },
    // 打开物流价格页面
    LogisticPrice() {
      window.open('https://blog.rakumart.us/logistics-comparison/')
    },
    // 视频介绍页面
    tutorialvideo() {
      window.open('https://www.youtube.com/@RakumartUS')
    },
    // 获取未读消息数量
    getNoticeUnreadNoticeCount() {
      this.$api
        .noticeUnreadNotice()
        .then((res) => {
          this.noticeNum = res.data.length


        })
        .catch((err) => { })
    },
    // 打开讲师宣传页
    toLeture(url) {
      let baseUrl = window.location.origin
      window.open(baseUrl + url, '_blank')

    },
    // 上传前图片校验
    avatarbefoUpload(file) {
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;

      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      this.loading = this.$loading({
        lock: true,
        text: this.$fanyi('图片上传中'),
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, .7)',
      })
    },
    // 图片上传成功事件
    handleAvatarSuccess(res) {
      this.$api.userPortraitSave({ head_img: res.data }).then((res) => {
        if (res.code != 0) return
        this.$api.EuropegetUserInfo().then((res) => {
          this.$store.commit('userData', res.data)
          this.loading.close()
          this.$forceUpdate()
        })
      })
    },
    getUser() {
      this.$api.EuropegetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
    },
    gologin() {
      this.$fun.toPage("/login");
    },
    // 历史关键词删除
    deleteOldKeyWord(i) {
      this.keyWordList.splice(i, 1);
      localStorage.setItem(
        "clientUsFrontPCkeyWord",
        JSON.stringify(this.keyWordList)
      );
      this.keyWordList = JSON.parse(
        localStorage.getItem("clientUsFrontPCkeyWord")
      );
    },
    // 切换翻译
    clickDropdown(value) {
      if (value != "") {
        this.$store.commit("Togglelanguagetype", value);
        location.reload();
      }
    },
    // 禁止输入款自动填充
    focus() {
      this.readonly = false;
    },
    // 复制图片上传
    handlePaste(event) {
      const items = (event.clipboardData || window.clipboardData).items;
      // 检查粘贴的项中是否有图片
      let hasImage = false;
      let hasText = false;
      for (let index in items) {
        const item = items[index];
        if (item.kind === "file" && item.type.indexOf("image/") !== -1) {
          // 读取图片文件
          const blob = item.getAsFile();
          // 校验文件格式是否为 JPG 或 PNG
          if (!["image/jpeg", "image/png", "image/jpg"].includes(blob.type)) {
            this.$message.error(
              this.$fanyi('请上传 image/jpeg, image/png, image/jpg 格式的图片')
            );
            return; // 如果格式不符合，直接返回
          }
          hasImage = true;
          this.httpup({
            file: blob
          })
          break; // 假设我们只需要处理第一个图片
        } else if (item.kind === "string") {
          // 检查是否为文本
          hasText = true;
        }
      }
      if (!hasImage && !hasText) {
        this.$message.error(
          '请上传 image/jpeg, image/png, image/jpg 格式的图片'
        );
      }
    },
    // 图片上传成功事件
    async successup(val) {

      let that = this
      try {
        // 创建 Image 对象
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // 解决跨域问题
        // 图片加载完成
        img.onload = () => {
          // 创建 Canvas
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // 设置 Canvas 宽高为图片的宽高
          canvas.width = img.width;
          canvas.height = img.height;

          // 将图片绘制到 Canvas 上
          ctx.drawImage(img, 0, 0);

          // 将 Canvas 转换为 Base64
          const base64 = canvas.toDataURL('image/png', 0.8); // 格式和质量可调整
          const base64String = base64.split('base64,')[1]; // 提取纯 Base64 数据
          // 构造上传数据
          let datas = {
            url: '/1/com.alibaba.fenxiao.crossborder/product.image.upload',
            uploadImageParam: JSON.stringify({
              imageBase64: base64String,
            }),
          };
          // 发起上传请求获取图片id
          that.$api.uploadImagePic(datas).then(res => {
            // 图搜
            that.pageLoading.close();
            // 图搜统计
            that.addKeyWord(val + '&imageId=' + res.data.result);
            if (that.$fun.isOfficialWeb()) {
              __bl.sum("用户上传图片搜索数量统计");
            }
            that.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + val + '&imageId=' + res.data.result);
          })
        };
        // 图片加载失败
        img.onerror = (error) => {
          that.$message.error(that.$fanyi('图片加载失败'))
          // 关闭加载动画
          that.pageLoading.close();
        };
        // 设置图片链接
        img.src = val;
      } catch (error) {
        that.$message.error(that.$fanyi('文件读取失败'))
        // 关闭加载动画
        that.pageLoading.close();

      }
    },
    // 手动上传送图片
    /**
     * 上传文件
     * @param {File} file - 要上传的文件
     * @returns {Promise} - 返回一个 Promise，resolve 时返回上传结果，reject 时返回错误信息
     */
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        const url = "https://laravel.rakumart.us/api/common/uploadFile";
        // 创建 FormData 对象
        const formData = new FormData();
        formData.append("file", file);

        // 创建 XMLHttpRequest 对象
        const xhr = new XMLHttpRequest();

        // 初始化请求
        xhr.open("POST", url, true);

        // 请求完成时的回调
        xhr.onload = () => {
          if (xhr.status === 200) {
            try {
              const res = JSON.parse(xhr.responseText);
              resolve(res.data); // 上传成功，返回结果
            } catch (error) {
              reject(new Error("解析响应数据失败"));
            }
          } else {
            reject(new Error("文件上传失败"));
          }
        };

        // 请求出错时的回调
        xhr.onerror = () => {
          reject(new Error("网络错误，请检查连接"));
        };

        // 发送请求
        xhr.send(formData);
      });
    },
    befoUpload(file) {
      // 上传钱进行校验
      this.$store.commit("getshoplistpage", 1);
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;

      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传 image/jpeg, image/png, image/jpg 格式的图片"));
        return isJPG;
      }

    },

    // 图片上传事件压缩上传
    async httpup(file) {
      // 开始加载
      this.pageLoading = this.$loading({
        lock: true,
        text: this.$fanyi("图片上传中"),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, .7)",
      });
      const proxy = this
      //  拿到图片进行压缩
      // 创建 FileReader 读取文件
      const reader = new FileReader();
      reader.readAsDataURL(file.file);

      reader.onload = function (e) {
        // 创建图片元素
        const img = document.createElement("img");
        img.src = e.target.result;

        img.onload = async () => {
          try {
            let { width, height } = img;

            // 限制最大宽度和高度
            const maxWidth = 800; // 最大宽度
            const maxHeight = 800; // 最大高度

            if (width > maxWidth || height > maxHeight) {
              const aspectRatio = width / height;
              if (width > height) {
                width = maxWidth;
                height = width / aspectRatio;
              } else {
                height = maxHeight;
                width = height * aspectRatio;
              }
            }

            // 创建 Canvas
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d");

            // 设置 Canvas 宽高
            canvas.width = width;
            canvas.height = height;

            // 清除 Canvas 内容
            context.clearRect(0, 0, width, height);

            // 绘制图片到 Canvas
            context.drawImage(img, 0, 0, width, height);

            // 动态调整压缩质量
            const targetSize = 307200; // 目标大小 300KB
            let quality = 0.6; // 初始质量（降低初始质量）

            // 将 Canvas 转换为 Blob
            const canvasToBlob = (quality) => {
              return new Promise((resolve) => {
                canvas.toBlob(
                  (blob) => resolve(blob),
                  "image/jpeg", // 强制输出为 JPEG 格式
                  quality
                );
              });
            };

            let blob = await canvasToBlob(quality);

            // 如果文件仍然过大，逐步降低质量
            while (blob.size > targetSize && quality > 0.1) {
              quality -= 0.05; // 减小步长
              blob = await canvasToBlob(quality);
            }

            // 将 Blob 转换为 File
            const compressedFile = new File([blob], file.file.name, {
              type: "image/jpeg", // 强制输出为 JPEG 格式
              lastModified: Date.now(),
            });

            // 上传到阿里云 OSS 或其他操作
            try {


              // 在这里进行压缩上传
              const imgOBJurl = await proxy.uploadFile(compressedFile)
              proxy.$store.commit("getsearchImg", imgOBJurl);
              await proxy.successup(imgOBJurl)

            } catch (e) {
              console.log(e);

            }
          } catch (error) {
            proxy.$message.error(proxy.$fanyi("图片压缩失败"));
          } finally {
            // 删除创建的图片元素
            img.remove();
          }
        };

        // 图片加载失败
        img.onerror = () => {
          proxy.$message.error(proxy.$fanyi('图片加载失败'));
        };
      };

      // 文件读取失败
      reader.onerror = () => {
        proxy.$message.error(proxy.$fanyi("文件读取失败"));
      };



    },

    changeHeight() {
      var that = this;
      window.onscroll = function () {

        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= 190) {
          if (that.isExpand) {
            document.getElementById("HomePageTopCon").style.height = "50px";
          } else {
            document.getElementById("HomePageTopCon").style.height = "80px";
          }
          document.getElementsByClassName("headMain")[0].style.height = "100px";
          // document.getElementById("HomePageTopCon").style.height = "80px";
          document.getElementById("qitasss").style.height = "0";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "150px";
          }


        } else if (scrollTop <= 100) {
          if (that.$store.state.isPluginInstalled == true) {
            document.getElementsByClassName("headMain")[0].style.height =
              "193px";
          } else {
            document.getElementsByClassName("headMain")[0].style.height =
              "145px";
          }
          if (that.isExpand) {

            document.getElementById("HomePageTopCon").style.height = "50px";
          } else {

            document.getElementById("HomePageTopCon").style.height = "80px";
          }

          document.getElementById("qitasss").style.height = "44px";
          if (document.getElementById("mask")) {
            document.getElementById("mask").style.top = "220px";
          }
        }
        // 是否显示top键 (ps:topBtn不在这个页面里，在sideBar.vue里)

        if (scrollTop >= 500) {
          document.getElementById("topBtn").style.display = "block";
        } else if (scrollTop <= 530) {
          document.getElementById("topBtn").style.display = "none";
        }
        // }
      };
    },
    // 获取搜索热词
    async gettopKeyword() {
      const res = await this.$api.topKeyword();
      if (res.code != 0) return;
      this.topKeywordlist = res.data;
    },
    // 返回首页
    backHome() {
      this.$fun.toPage("/");
      this.$store.commit("getsearchInfo", "");
    },
    // 跳转搜索页
    search() {

      // 判断是否是链接,如果是链接就跳转到相应页面
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688./);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let regrakumartbr = RegExp(/iid/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg2 = /id=(\d+)/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg5 = /tk=([^&\s]+)/; //taobao手机链接
      var reg6 = /[?|&]iid=(\d{10,15})/;

      // 判断是哪个网站的商品链接
      if (regtaobao.test(this.$store.state.searchInfo)) {
        // 淘宝进入商品详情页
        id = this.$store.state.searchInfo.match(reg2)[1];
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过淘宝链接搜索进入详情数量统计');
        }

        this.$fun.toCommodityDetails({ shop_type: "taobao", goods_id: id, type: 'taobaolinkSearch' });
        return false;
      } else if (reg1688.test(this.$store.state.searchInfo)) {
        var substring = "detail.1688.com";
        // 转换为小写后进行比较
        if (this.$store.state.searchInfo.toLowerCase().includes(substring.toLowerCase())) {
          if (this.$store.state.searchInfo.match(reg1) == null) {
            id = this.$store.state.searchInfo.match(reg4)[1];
          } else {
            id = this.$store.state.searchInfo.match(reg1)[1];
          }
          // 1688进入商品详情页
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('用户通过1688链接搜索进入详情数量统计');
          }
          this.$fun.toCommodityDetails({ shop_type: "1688", goods_id: id, type: '1688linkSearch' });
          return false;
        } else {
          // // // 说明可能是店铺
          // // console.log("字符串中不包含 'detail.1688.com'");
          // // 跳转
          // this.$fun.routerToPage("/commoditysearch?keyword=" + this.$store.state.searchInfo, true);
        }



      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        // 天猫链接进入商品详情

        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过天猫链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: "taobao", goods_id: id, type: 'tmalllinkSearch' });
        return false;
      } else if (regrakumart.test(this.$store.state.searchInfo)) {
        // rakumart 链接搜索进入详情页
        id = this.$store.state.searchInfo.match(reg3)[1];
        let regexty = /[?&]type=([^&#]+)/;
        let match = this.$store.state.searchInfo.match(regexty);
        // 获取type参数的值
        let typeValue = match ? match[1] : null;
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过rakumart链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: typeValue, goods_id: id, type: 'rakumartlinkSearch' });
        return
      } else if (regrakumartbr.test(this.$store.state.searchInfo)) {
        // rakumart 链接搜索进入详情页
        id = this.$store.state.searchInfo.match(reg6)[1];
        let regexty = /[?&]type=([^&#]+)/;
        let match = this.$store.state.searchInfo.match(regexty);
        // 获取type参数的值
        let typeValue = match ? match[1] : null;
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('用户通过rakumart链接搜索进入详情数量统计');
        }
        this.$fun.toCommodityDetails({ shop_type: typeValue, goods_id: id, type: 'rakumartlinkSearch' });
        return
      }
      //进行判断
      else {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum("用户发起关键词搜索数量统计");
        }
      }

      if (this.$store.state.searchInfo.trim().length !== 0) {
        if (this.$store.state.searchInfo == this.$route.query.keyword) {
          this.$router.go(0);
          return false;
        }
        if (!this.$store.state.searchInfo) {
          this.$message(this.$fanyi("请输入关键词搜索"));
          return false;
        }
        this.addKeyWord(this.$store.state.searchInfo);
        this.$fun.toPage(
          "/commoditySearch?keyword=" +
          this.$store.state.searchInfo +
          "&type=keywordSearch"
        );
      }
    },
    // 历史搜索
    Historicalsearch(val) {
      if (val.includes("rakumart-eu.oss-ap-northeast-1.aliyuncs.com")) {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum("用户上传图片搜索数量统计");
        }
        this.$fun.toPage("/commoditySearch?type=imgSearch&imgUrl=" + val);
      } else {
        this.$store.state.searchInfo = val;
        this.search();
      }
    },
    //加入搜索历史
    addKeyWord(val) {
      if (val != "") {
        if (localStorage.getItem("clientUsFrontPCkeyWord") != null) {
          let keyWordList = JSON.parse(
            localStorage.getItem("clientUsFrontPCkeyWord")
          );
          if (keyWordList.indexOf(val) == -1) {
            if (keyWordList.length >= 10) {
              keyWordList.splice(0, 1);
              keyWordList.push(val);
              localStorage.setItem(
                "clientUsFrontPCkeyWord",
                JSON.stringify(keyWordList)
              );
            } else {
              keyWordList.push(val);
              localStorage.setItem(
                "clientUsFrontPCkeyWord",
                JSON.stringify(keyWordList)
              );
            }
          }
        } else {
          let keyWordList = [];
          keyWordList.push(val);
          localStorage.setItem(
            "clientUsFrontPCkeyWord",
            JSON.stringify(keyWordList)
          );
        }
      }
    },
    // 退出登录
    loginOut() {
      localStorage.removeItem("user_token");
      this.$store.commit("userData", null);
      this.$store.commit("setcartCount", 0);
      this.$store.commit("Sidebardatacleanup");
      localStorage.removeItem("clientUsFrontPCkeyWord");
      let requireAuth = this.$route.matched[0].meta.requireAuth;
      if (requireAuth) {
        this.$fun.toPage("/");
      }
    },

  },
};
</script>
<style lang="scss">
.popoversearchTip {
  margin-left: 120px;
  max-height: 636px;
  overflow-y: scroll;
  padding: 18px 24px;

  /* 隐藏水平和垂直滚动条 */
  &::-webkit-scrollbar {
    display: none;
  }

  .Historicalrecord {
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 15px;

    .Historical {
      min-width: 80px;

      font-size: 14px;
      color: #c6c6c6;
      padding-top: 4px;
      margin-right: 20px;
    }

    .record {
      display: flex;
      align-items: center;

      flex-wrap: wrap;

      .record-item {
        min-height: 28px;
        margin-bottom: 10px;

        &:hover {
          cursor: pointer;
        }

        padding: 0px 10px;

        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        color: #999999;
        border-radius: 6px;
        margin-right: 10px;

        .el-icon-close {
          margin-left: 5px;

          font-size: 16px;
        }
      }
    }
  }

  .searchitem {
    h2 {
      font-size: 16px;
      color: #000000;
      font-weight: bold;
      margin-top: 15px;
    }

    .classification-item {
      display: flex;
      margin-right: 12px;
      flex-wrap: wrap;

      span {
        min-height: 30px;
        display: flex;
        align-items: center;
        font-size: 14px;
        justify-content: center;
        padding: 0 10px;
        border-radius: 6px;
        background-color: #f5f5f5;
        margin-top: 12px;
        margin-right: 12px;

        &:hover {
          cursor: pointer;
          background: linear-gradient(90deg, #ff7e3e, #ff5010, #fe4b0f);
          color: #fff;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped="scoped">
@import "../../css/mixin";

.u-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user_name {
  display: inline-block;
  width: 90px;
}

.flexAndCenter {
  display: flex;

  align-items: center;
}

.headMain {
  // min-height: 160px;
  z-index: 9;

  #HomePageTop {
    width: 100%;
    // background-color: white;
    position: fixed;
    top: 0px;
    z-index: 999;

    .booosdj {
      background-color: black;

      .qiTa {
        // width: $pageWidth;
        width: 84vw;
        margin: 0 auto;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;

        .zhiNan {
          padding-left: 6px;

          span {
            margin-right: 1.5vw;
            font-weight: 400;
            font-size: 14px;

            color: white;
            cursor: pointer;

            &:hover {
              .dropDownBox {
                height: 95px;
              }
            }

            position: relative;

            .dropDownBox {
              transition: 0.3s;
              position: absolute;
              top: 30px;
              left: 50%;
              transform: translate(-50%, 0);
              width: 120px;
              height: 0;
              overflow: hidden;
              z-index: 23;
              background: #ffffff;
              box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
              border-radius: 10px;

              >span {
                display: block;
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                width: 120px;
                height: 32px;
                font-size: 16px;
                font-weight: 400;

                &:hover {
                  color: $homePageSubjectColor;
                  background: #e2e5ff;
                }
              }
            }
          }

          .isactive {
            color: #FF730B;
          }
        }

        .huiLv {
          border-radius: 4px;
          font-size: 13px;

          font-weight: 500;
          color: white;
          text-align: center;

          .numd {
            font-weight: bolder;
          }
        }

        .China-ukhandover {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 20px;
        }

        .active {
          color: #ff730b;
        }
      }
    }


    .expanding {
      height: 50px;
    }

    #HomePageTopCon {
      background-color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      transition: linear 0.2s;

      .con_main {
        width: $pageWidth;
        margin: 0 auto;
        min-height: 80px;
        display: flex;
        align-items: center;
        transition: linear 0.2s;


        >div {
          display: flex;
          align-items: center;

          &.logoBox {
            justify-content: flex-start;
            width: 195px;
            height: 50px;

            #logo {
              transition: 0.5s;
              width: 195px;
              height: 50px;
              cursor: pointer;
            }
          }

          &.expanding_img {
            width: 116px;
            height: 30px;
            padding: 0;

            #logo {
              width: 100%;
              height: 100%;
            }
          }

          &.searchBoxDiv {
            width: 720px;
            height: 50px;
            justify-content: flex-start;
            display: flex;
            border: 2px solid #ff730b;
            border-radius: 4px;
            margin-left: 30px;
            margin-right: 40px;

            /deep/.el-select {
              flex: 0 0 185px;

              .el-input__inner {
                border: none;
                height: 54px !important;
                font-size: 14px;
                font-weight: 400 !important;

                &::placeholder {
                  font-size: 14px;
                  color: #999 !important;
                }
              }

              .el-input__icon {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .fg {
              height: 20px;
              width: 1px;
              background-color: #6d6d6d;
            }

            /deep/.el-input {
              flex: 1;

              .el-input__inner {
                border: none;
                font-size: 13px;
                padding-left: 15px;
                font-weight: 500;
                color: black;
              }
            }

            /deep/.el-upload {
              width: 54px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              border: none;

              img {
                width: 30px;
              }
            }

            .searchInputBoxFg {
              width: 1px;
              height: 16px;
              background-color: #999;
            }

            /deep/.el-button {
              width: 66px;
              height: 50px;
              border: none;
              background: white;
              border-radius: 0 4px 4px 0px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #ff730b;
              background: #ff730b;

              img {
                width: 30px;
                height: 30px;
              }
            }
          }

          &.operate {
            display: flex;

            .userDetail {
              $userImgSize: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              position: relative;
              margin-right: 20px;

              &.notLogin {
                text-align: right;
                width: 234.56px;
                justify-content: flex-end;

                span {
                  font-weight: 400;
                  font-size: 13px;
                  color: #222222;
                  text-decoration-line: underline;
                  cursor: pointer;
                }



                .fg {
                  text-decoration-line: none;
                  margin: 0 5px;
                  cursor: none;

                }

                .register_box {
                  a {
                    font-weight: 400;
                    font-size: 13px;
                    color: #FF730B;
                    text-decoration-line: underline;
                  }

                }


                img {
                  width: 40px;
                  height: 40px;
                  margin-right: 10px;
                }
              }

              &:hover {
                .dropDownBox {
                  height: 64px;
                }
              }

              .dropDownBox {
                transition: 0.3s;
                position: absolute;
                top: 100%;
                left: 49px;
                width: 120px;
                height: 0;
                overflow: hidden;
                background: #ffffff;
                box-shadow: 0px 0px 10px 0px rgba(136, 136, 136, 0.2);
                border-radius: 10px;
                z-index: 777;

                >span {
                  display: block;
                  cursor: pointer;
                  text-align: center;
                  line-height: 32px;
                  width: 120px;
                  height: 32px;
                  font-size: 16px;

                  font-weight: 400;

                  &:hover {
                    color: $homePageSubjectColor;
                    background: #e2e5ff;
                  }
                }
              }

              // 用户头像大小
              $userHeadImgSize: 40px;

              .upload-demo {
                position: relative;

                &:hover .showDow {
                  opacity: 1;
                }

                .showDow {
                  background-color: rgba($color: #000000, $alpha: 0.5);
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  opacity: 0;
                  transition: 0.3s;
                  top: 0;

                  border-radius: 50%;
                  width: 40px;
                  height: 40px;

                  color: white;
                  font-size: 10px;
                }
              }


              .userHeadImg {
                border-radius: 50%;
                width: $userHeadImgSize;
                height: $userHeadImgSize;
                margin-right: 10px;
              }

              .moRenTouXiang {
                width: $userHeadImgSize;
                height: $userHeadImgSize;
                margin-right: 5px;
              }

              span {
                font-size: 14px;

                font-weight: 500;
                color: #000000;





                &:nth-child(3) a {
                  color: $homePageSubjectColor;
                }
              }

              .userLoginInfoBox {
                .info_referce {
                  display: flex;
                  align-items: center;

                  span {
                    font-weight: 400;
                    font-size: 12px;
                    color: #222222;

                    &:nth-child(1) {
                      padding-top: 2px;
                    }
                  }


                }

                .centerBtnBox {
                  // 亲强制不换行
                  white-space: nowrap;


                  .centerBtn {
                    font-weight: 400;
                    font-size: 12px;
                    color: #FF730B;
                    text-decoration-line: underline;

                    &:hover {
                      cursor: pointer;
                    }
                  }

                  .routerBtn {
                    cursor: pointer;
                    font-weight: 400;
                    font-size: 12px;
                    color: #222222;

                    &:hover {
                      color: $homePageSubjectColor;
                    }
                  }
                }


              }
            }

            .huilv {
              margin-right: 20px;
            }

            .cartBtn {
              min-width: 80px;

              button {
                display: flex;
                border-radius: 33px;
                align-items: center;
                font-size: 14px;
                font-weight: 500;

                margin-left: 15px;


                .carticon {
                  position: relative;



                  img {
                    margin-right: 10px;
                  }

                  span {
                    position: absolute;
                    right: 0;
                    top: -5px;
                    min-width: 20px;
                    height: 20px;
                    background: #ff730b;
                    line-height: 20px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 50%;
                  }
                }

                .cart_name {
                  font-weight: 400;
                  font-size: 12px;
                  color: #FF730B;
                  text-align: left !important;
                }

                .defaultCart {
                  color: #222222;
                }
              }
            }

            .notice_box {
              min-width: 80px;
              margin-left: 20px;

              button {
                display: flex;
                align-items: center;

                .cart_name {
                  text-align: left !important;
                  padding-left: 2px;

                }

                .carticon {
                  position: relative;


                  img {
                    margin-right: 5.5px;
                  }



                  span {
                    position: absolute;
                    right: 0;
                    top: -5px;
                    min-width: 20px;
                    height: 20px;
                    background: #ff730b;
                    line-height: 20px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    border-radius: 50%;
                  }
                }
              }
            }
          }
        }


      }

      .expand_box {
        height: 100%;
        font-weight: 400;
        font-size: 12px;
        color: #222222;
        right: 30px;
        cursor: pointer;
        display: flex;
        position: absolute;
        align-items: center;
        transition: all 0.3s linear;

        img {
          margin-left: 6px;
        }
      }


    }


  }
}

.upImg {

  height: 40px;

  /deep/.el-upload {
    display: flex;
    align-items: center;

    span {
      display: flex;
      height: 40px;
    }
  }
}
</style>
