<template>
  <div id="CommoditySearch">
    <Dialog ref="dialog" :config="config">
      <!-- <span>插槽内容</span> -->
    </Dialog>
    <HomePageTop></HomePageTop>

    <!-- 关键词搜索 -->
    <nav class="strSearch" v-if="!isImgSearch">
      <div class="topCon">
        <span class="keyword">{{ $fanyi('关键词') }}：{{ $route.query.keyword }}
        </span>

        <el-popover popper-class="qieHuanPaiBan" placement="bottom-end" trigger="click" ref="qiehuanSel">
          <div v-if="!Crosswise" @click="
            Crosswise = true
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('横版') }}</span>
            <img src="../../assets/icon/array1.svg" />
          </div>
          <div v-else @click="
            Crosswise = false
          $refs.qiehuanSel.doClose()
            " class="PaiBanOpt">
            <span>{{ $fanyi('竖版') }}</span>
            <img src="../../assets/icon/array2.svg" />
          </div>

          <div class="crosswise" slot="reference">
            <div v-if="!Crosswise">
              <img src="../../assets/icon/array2.svg" />
            </div>
            <div v-else>
              <img src="../../assets/icon/array1.svg" />
            </div>
            <img class="downIcon" src="../../assets/icon/drop_down  .svg" />
          </div>
        </el-popover>
      </div>
      <!-- 搜索接口 -->

      <div class="searchitem" v-for="(item, index) in keywordSNQuerylist" :key="index">
        <div class="searchitem-title">{{ item.translateName }} :</div>
        <div class="searchitem-list">
          <span @click="setSnId(item, childrenIndex)" :class="{ spanactive: item.active == childrenIndex }"
            v-for="(ite, childrenIndex) in item.children" :key="ite.id">{{ ite.translateName
            }}</span>
        </div>
      </div>
      <!-- 严选 -->
      <div class="Strictselection">
        <div class="fornecedor">{{ $fanyi('严选') }}:</div>
        <!-- 1688精选商品  -->

        <div class="Strictselection-ite" style="margin-right: 80px;">
          <el-checkbox v-model="shopchecked" @change="Selectchange" size="large" /><span>{{
            $fanyi('1688精选商品') }}</span>
        </div>
        <!-- 严选工厂  -->
        <div class="Strictselection-ite" style="margin-right: 122px;">
          <el-checkbox v-model="selectionoffactorieschecked" @change="Selectchange" size="large" />
          <span>{{ $fanyi('1688精选工厂') }}</span>
        </div>

        <!-- 店铺点评 -->

        <div class="Strictselection-ite">
          <span class="selett-title">{{ $fanyi('店铺点评') }}</span>
          <el-select v-model="shopComment" @change="Selectchange" placeholder="Select" size="large">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
      <!-- 优势 -->
      <div class="advantage">

        <div class="fornecedor">{{ $fanyi('优势') }}:</div>
        <!-- 当日出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Samedayshipment" @change="Selectchange" size="large" /><span>{{
            $fanyi('当日出货') }}</span>
        </div>
        <!-- 24小时以内出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Shipmentwithin24hours" @change="Selectchange" size="large" /><span>{{
            $fanyi('24小时以内出货') }}</span>
        </div>
        <!-- 48小时以内出货 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Shipmentwithin48hours" @change="Selectchange" size="large" /><span>{{
            $fanyi('48小时以内出货') }}</span>
        </div>
        <!-- 7天无理由退换商品 -->
        <div class="advantage-ite">
          <el-checkbox v-model="noreasontoreturnandexchangegoodsfor7days" @change="Selectchange" size="large" /><span>{{
            $fanyi('7天无理由退换商品') }}</span>
        </div>
        <!-- 1件起订 -->
        <div class="advantage-ite">
          <el-checkbox v-model="Orderonefromthebeginning" @change="Selectchange" size="large" /><span>{{
            $fanyi('1件起订') }}</span>
        </div>
      </div>
      <!-- 综合排序 -->
      <div class="sort">
        <div class="sort-item" @click="switchSort(6)" :class="{ activecolor: sortindex }">
          {{ $fanyi('综合排序') }}
        </div>
        <!-- 月贩卖数 -->
        <div class="sort-item" @click="switchSort(0)">
          <span :class="{ activecolor: StrictselectionArr[0].bottom }">{{
            $fanyi('月贩卖数')
          }}</span>
          <img v-if="!StrictselectionArr[0].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
          <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
        </div>
        <!-- 复购率 -->
        <div class="sort-item" @click="switchSort(1)">
          <span :class="{ activecolor: StrictselectionArr[1].bottom }">{{
            $fanyi('复购率')
          }}</span>
          <img v-if="!StrictselectionArr[1].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
          <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
        </div>
        <!-- 价格排序 -->
        <div class="sort-item" @click="switchSort(2)">
          <span :class="{
            activecolor:
              StrictselectionArr[2].bottom || StrictselectionArr[2].top,
          }">{{ $fanyi('价格') }}</span>
          <div class="pricesort">
            <img v-if="!StrictselectionArr[2].top" src="../../assets/commoditysearch/top.png" alt="" />
            <img v-else src="../../assets/commoditysearch/topactive.png" alt="" />
            <img v-if="!StrictselectionArr[2].bottom" src="../../assets/commoditysearch/bottom.png" alt="" />
            <img v-else src="../../assets/commoditysearch/botomactive.png" alt="" />
          </div>
        </div>
        <!-- 最高最低价格 -->
        <div class="sort-item">
          <div class="inputbox">
            <span>￥</span><input v-model="priceStart" :placeholder="$fanyi('最低价格')" type="number" />
          </div>
          <p>—</p>
          <div class="inputbox">
            <span>￥</span><input v-model="priceEnd" :placeholder="$fanyi('最高价格')" type="number" />
          </div>

          <div class="qrbutton" @click="(form.page = 1), getData(true, 'Sort')">
            {{ $fanyi('确认') }}
          </div>
        </div>
      </div>
    </nav>

    <!-- 图片搜索的搜索信息显示栏 -->
    <nav class="imgSearch" v-if="isImgSearch">
      <div class="topCon">
        <div class="keyword">{{ $fanyi('搜索图片') }}

        </div>
        <div class="imgSearchRight">

          <div class="imgSearchTitle">
            <div class="imgSearchTitleleft">
              {{ $fanyi('点击切换图像,可以对图像进行多个上传') }}
            </div>
            <div class="imgSearchTitleright">
              (<i>{{ fileList.length + 1 }}</i>/{{ 6 }})
              <span @click.stop="clearAndReUpload">
                {{ $fanyi('清空并重新上传') }}
              </span>
            </div>
          </div>
          <el-popover popper-class="qieHuanPaiBan" placement="bottom-end" trigger="click" ref="qiehuanSel">
            <div v-if="!Crosswise" @click="
              Crosswise = true
            $refs.qiehuanSel.doClose()
              " class="PaiBanOpt">
              <span>{{ $fanyi('横版') }}</span>
              <img src="../../assets/icon/array1.svg" />
            </div>
            <div v-else @click="
              Crosswise = false
            $refs.qiehuanSel.doClose()
              " class="PaiBanOpt">
              <span>{{ $fanyi('竖版') }}</span>
              <img src="../../assets/icon/array2.svg" />
            </div>

            <div class="crosswise" slot="reference">
              <div v-if="!Crosswise">
                <img src="../../assets/icon/array2.svg" />
              </div>
              <div v-else>
                <img src="../../assets/icon/array1.svg" />
              </div>
              <img class="downIcon" src="../../assets/icon/drop_down  .svg" />
            </div>
          </el-popover>
        </div>
      </div>
      <!-- 主图片区域 -->
      <div class="imgSearchConter">
        <div class="leftimgsearch">
          <el-popover placement="right" trigger="manual" :width="280" v-model="mainImageAreavisible">
            <div class="ImageSelectorBox">
              <ImageSelector :imageSrc="imgUrl" @selection="handselection" :initialSelection="newinitialSelection"
                :containerSize="252" />
              <div class="footer">
                <!-- 取消 -->

                <el-button @click="mainImageAreavisible = false">{{
                  $fanyi('取消')
                }}</el-button>
                <!-- 确认 -->
                <el-button @click="ImageAreaconfirm" type="primary">{{
                  $fanyi('确认')
                }}</el-button>
              </div>
            </div>
            <template slot="reference">
              <div class="mainImageArea">
                <ImageSelector :imageSrc="imgUrl" :initialSelection="initialSelection" :containerSize="85"
                  :draggable="false" :showCorners="false" />
                <div class="boxSelectionSubject">
                  <img @click.stop="selectionSubjectClick" src="../../assets/commoditysearch/box.svg" alt="" />
                </div>
              </div>
            </template>
          </el-popover>
          <div class="imgsBox">
            <div class="img" @click="imgClick(imgItem)" :class="{ imgactive: imgItem.activeChecked == true }"
              v-for="(imgItem, index) in imageRecognitionlist">
              <el-image :src="imgItem.url" />
              <img v-if="imgItem.activeChecked == true" class="select" src="../../assets/commoditysearch/select.svg"
                alt="" />
            </div>
          </div>
        </div>

        <!-- 右侧上传 -->
        <div class="uploadOnTheRightSide">
          <!-- 上传组件 -->
          <div class="fileListBox">
            <div class="fileItem" @click.stop="fileItemClick(fileItem)" v-for="(fileItem, i) in fileList" :key="i">
              <el-image :src="fileItem.imgUrl" />
              <img @click.stop="deletefileItem(i)" class="deleteimg" src="../../assets/commoditysearch/delete.png"
                alt="" />
            </div>
            <div class="uploadBox" v-if="fileList.length < 5" v-loading="uploadloading" @click="triggerUpload">
              <i class="el-icon-plus"></i>
              <div class="text">
                <p>{{ $fanyi('上传图片') }}</p>
                <p>({{ $fanyi('多选') }})</p>
              </div>
              <!-- 隐藏的 input 文件上传 -->
              <input type="file" ref="fileInput" style="display: none" multiple @change="handleFileUpload" />
            </div>
          </div>
        </div>
      </div>
    </nav>


    <!-- 没有找到商品 -->
    <div v-if="goodsListIsNull" class="goodsListIsNull">
      {{
        $route.query.type == 'imgSearch'
          ? $fanyi('没有找到商品，请换一张图片试试')
          : $fanyi('没有找到商品，请换一个关键词试试')
      }}
    </div>
    <!-- 搜索结果块状 -->
    <div class="SearchConterCrosswise" v-loading="loading" v-else-if="Crosswise">
      <div class="SearchConterCrosswiseCon" v-for="(item, index) in lists.result" :key="index">
        <!-- <button
          class="similarProducts"
          @click="
            $store.commit('getsearchImg', item.imgUrl);
            $fun.toPage('/commoditySearch?type=imgSearch');
            $router.go(0);
          "
        >
          {{ $fanyi("查看相似") }}
        </button> -->
        <!-- 放图片的div -->
        <div class="SearchConterCrosswiseImg" @click="toCommodityDetails(item)">
          <!-- 遮罩 -->
          <!-- <div class="shade" @click="$fun.toCommodityDetails(item.goods_id)">
            <div><img src="../../assets/newImg/搜索小.png" alt="" /></div>
          </div> -->
          <!-- <img class="goodsImg" :src="item.imgUrl" /> -->
          <el-image :src="item.imgUrl" style="width: 262px; height: 262px" class="biggoodsImg">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
          <!-- <loadingImg :src="item.imgUrl" /> -->
        </div>
        <!-- 显示其他信息的div -->
        <div class="rests">
          <p class="goodsTitle" :title="item.title">{{ item.title }}</p>
          <p>
            <span>${{
              $fun.EURNumSegmentation(
                $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
              )
            }}（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）</span>
          </p>
        </div>
        <!-- 月销量 -->
        <div class="Monthlysalesvolume">{{ $fanyi('月销量') + ': ' }} {{ item.monthSold }}</div>
        <!-- 复购率 -->
        <div class="Repurchaserate">{{ $fanyi('复购率') + ': ' }} {{ item.repurchaseRate }} %</div>
      </div>
      <div v-if="slideLoading && !Loadingcompleted" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="../../assets/newImg/order/loading.gif" />
          <div>{{ $fanyi('加载中') }}</div>
        </div>
      </div>
    </div>
    <!-- 搜索结果横向 -->

    <div class="SearchConterVertical" v-loading="loading" v-else>
      <div @click="
        toCommodityDetails(item)
        " v-for="(item, index) in lists.result" :key="index" class="item">
        <el-popover placement="right" trigger="hover">
          <img :src="item.imgUrl" alt="" style="width: 300px; height: 300px" />
          <el-image class="hengxiang" :src="item.imgUrl" slot="reference">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
            <div slot="placeholder">
              <i class="el-icon-loading"></i>
            </div>
          </el-image>
        </el-popover>

        <!-- <img class="goodsImg" src="../../assets/newImg/加载中.gif" /> -->
        <div class="VerticalInfo">
          <span class="vGoodsTitle">{{ item.title }}</span>
          <span class="price">${{
            $fun.EURNumSegmentation(
              $fun.ceil(item.goodsPrice * $store.state.exchangeRate)
            )
          }}（{{ $fun.RMBNumSegmentation(item.goodsPrice) }}￥）</span>
          <div class="xiaoliag">
            <p>{{ $fanyi('月销量') }}：{{ item.monthSold }} {{ $fanyi('件') }}</p>
            <p>{{ $fanyi('复购率') }}：{{ item.repurchaseRate }} {{ '%' }}</p>
          </div>

        </div>
        <span class="collect" @click.stop="favoriteGoodsAdd(item)">
          <div>
            <img v-if="item.goodsFavoriteStatus" src="../../assets/icon/collection2.png" />
            <img v-else src="../../assets/icon/collection1.png" />
          </div>
        </span>
      </div>
      <div v-if="slideLoading && !Loadingcompleted && !loading" class="keywordLoadingContainer">
        <div class="flexAndCenter">
          <img alt="" src="../../assets/newImg/order/loading.gif" />
          <div>{{ $fanyi('加载中') }}</div>
        </div>
      </div>
    </div>

    <!--      <el-pagination-->
    <!--        @current-change="handleCurrentChange"-->
    <!--        :current-page="form.page"-->
    <!--        :page-size="20"-->
    <!--        layout="total, prev, pager, next, jumper"-->
    <!--        :total="total"-->
    <!--      >-->
    <!--      </el-pagination>-->
    <!-- <div class="allpag" v-if="total > 0">
      <span></span>
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="form.page" :page-size="20"
        layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div> -->

    <Foot></Foot>
  </div>
</template>

<script>
import Foot from '../../components/foot/Foot.vue'
import Dialog from '../../components/public/Dialog'
import HomePageTop from '../../components/head/HomePageTop.vue'
import ImageSelector from '../../components/ImageSelector/index.vue'
export default {
  name: 'search',
  // // 组件进入调用的函数钩子

  // 组件离开调用的和函数够子
  beforeRouteLeave(to, from, next) {
    if (to.path !== '/productDetails') this.$store.commit('getshoplistpage', 1)
    next()
  },
  data() {
    return {
      form: {
        page: 1,
      }, //搜索条件

      total: 0,
      keyWord: '',
      imageId: '', // 存储图搜id
      imgUrl: '',// 图片
      // 分割图片数据
      region: '',// 坐标
      initialSelection: null,//默认主图切割坐标
      newinitialSelection: null,// 弹层主图坐标
      mainImageAreavisible: false,// 是否展示弹层
      imageRecognitionlist: [], // 图片切割数组
      fileList: sessionStorage.getItem('AmericanfileList') ? JSON.parse(sessionStorage.getItem('AmericanfileList')) : [],// 上传图片列表
      uploadloading: false,// 加载状态
      type: '',
      shopComment: '',// 店铺点评
      shopchecked: '',// 1688 严选
      priceStart: '',// 最低价格
      priceEnd: "",// 最高价格
      sortindex: '',// 综合排序
      snId: "",// 搜索栏选中的id
      sort: '',// 排序
      filter: '',// 筛选
      selectionoffactorieschecked: '', // 严选工厂
      Samedayshipment: '',// 当日出货
      Shipmentwithin24hours: '',// 24小时以内发货
      Shipmentwithin48hours: '',// 48小时以内发货
      noreasontoreturnandexchangegoodsfor7days: '',// 七天无理由退款
      Orderonefromthebeginning: '',// 1件起订
      keywordSNQuerylist: [],// 关键字搜索列表
      slideLoading: false,
      Loadingcompleted: false,
      StrictselectionArr: [
        {
          bottom: false,
        },
        {
          bottom: false,
        },
        {
          top: false,
          bottom: false,
        },
      ],
      options: [
        {
          value: '', // 全部
          label: this.$fanyi('全部'),
        },
        {
          value: 'totalEpScoreLv1', // 5 星
          label: this.$fanyi('5星'),
        },
        {
          value: 'totalEpScoreLv2', // 4.5-5
          label: this.$fanyi('4.5星~5星'),
        },
        {
          value: 'totalEpScoreLv3', // 4-4.5
          label: this.$fanyi('4星~4.5星'),
        },
      ],


      selectIconIndex: 0,
      shop_type: 1688,
      lists: {
        result: []
      },
      loading: false,
      tiShi: this.$fanyi('加载中'), //提示语句
      // 是否是横版
      Crosswise: true,
      isImgSearch: false,
      notCreate: true,
      goodsListIsNull: false, //搜索结果是否有商品
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确定')],
      },
    }
  },
  components: {
    HomePageTop,
    Foot,
    Dialog,
    ImageSelector,
  },
  computed: {},
  created() {

    if (this.$route.query.type == 'imgSearch') {
      this.isImgSearch = true
      this.imageId = this.$route.query.imageId
      this.imgUrl = this.$route.query.imgUrl
    }
    this.initialized()
    // 调用多语言搜索导航
    this.keywordSNQuerysearch()
  },
  watch: {
    $route: {
      handler(route) {
        if (route.name == 'CommoditySearch') {
          if (this.$route.query.type == 'imgSearch') {
            this.isImgSearch = true
            this.imageId = this.$route.query.imageId
          } else {
            this.isImgSearch = false
          }
          this.initialized()
        }
      },
    },
  },
  methods: {
    // 添加收藏商品
    favoriteGoodsAdd(item) {
      if (!this.$store.state.userInfo) {
        return this.$refs.dialog.open(
          this.$fanyi('是否前往登录?'),
          () => {
            this.$fun.toPage('/login')
          },
          () => { }
        )
      }

      let datas = {
        goods_id: item.goods_id,
        title: item.title,
        image_url: item.imgUrl,
        price: item.goodsPrice,
        shop_type: item.shop_type,
      }
      if (!item?.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return
          this.$message.success(this.$fanyi('添加收藏成功'))
          item.goodsFavoriteStatus = !item.goodsFavoriteStatus
          this.$forceUpdate()
        })
      } else {
        this.$api
          .favoriteGoodsDelete({
            goods_id: item.goods_id,
          })
          .then((res) => {
            if (res.code != 0) return
            this.$message.success(this.$fanyi('取消收藏成功'))
            item.goodsFavoriteStatus = !item.goodsFavoriteStatus
            this.$forceUpdate()
          })
      }
    },

    // 路由改变时的操作
    initialized() {
      // 如果路由中带有CN就代表是点击首页的分类跳转过来的，需要读取路由中的中文关键词以进行精确搜索，否则获取路由中的普通关键词
      if (!!this.$route.query.CN) {
        this.keyWord = this.$fun.fromCode(this.$route.query.CN)
      } else if (this.$route.query.keyword) {
        if (this.$fun.to_link(this.$store.state.searchInfo) != false) {
          this.keyWord = this.$store.state.searchInfo
        } else {

          this.keyWord = this.$route.query.keyword
        }
      }
      // 讲关键词传到vuex以传值到头部搜索框
      this.$store.commit('getsearchInfo', this.$store.state.searchInfo)
        ; (this.form.keyWords = this.keyWord),
          (this.form.shop_type = this.shop_type)

      this.$forceUpdate()
      this.getData(true)

    },

    async keywordSNQuerysearch() {
      const res = await this.$api.keywordSNQuerysearch({
        keyword: this.keyWord,
      })
      if (res.code !== 0) {
        return
      }
      this.keywordSNQuerylist = res.data
    },

    // 获取列表数据
    async getData(bolb, type, Isnewregion) {
      if (type) {
        this.lists.result = []
      }
      this.goodsListIsNull = false
      this.Loadingcompleted = false
      this.loading = bolb
      this.form.pageSize = 20

      this.form.snId = this.snId
      this.form.sort = this.sort
      this.form.filter = this.filter
      this.form.priceStart = this.priceStart, // 开始价格
        this.form.priceEnd = this.priceEnd // 结束价格

      // 如果路由中的type值为imgSearch就代表是图片搜索
      if (this.$route.query.type == 'imgSearch') {
        // 图片搜索
        await this.$api
          .imageSearchProduct({
            imageId: this.imageId,
            shop_type: this.shop_type,
            picUrl: this.imgUrl,
            page: this.form.page,
            pageSize: this.form.pageSize,
            region: this.region,
            // snId: this.snId,
            // snId: this.snId,
            // sort: this.sort,
            // filter: this.filter,
            // priceStart: this.priceStart, // 开始价格
            // priceEnd: this.priceEnd, // 结束价格
          })
          .then((result) => {
            if (result.code != 0) {
              this.goodsListIsNull = true
              return
            }
            this.loading = false
            this.imageId = result.data.imageId
            this.slideLoading = false
            if (result.data.length == 0) {
              this.goodsListIsNull = true
              return
            }

            if (result.data.region && result.data.region.length > 0) {
              this.initialSelection = result.data.region[0].location
              this.getImageSliceList(result.data.region, Isnewregion)
            }
            this.lists.result = [...this.lists.result, ...result.data.data]

            this.total = result.data.total

            if (result.data.result.length == 0 && result.data.total == 0 && this.form.page > 1) {
              this.Loadingcompleted = true
            } else if (result.data.result.length == 0 && result.data.total == 0 && this.form.page == 1) {
              this.goodsListIsNull = true
            }

          })
          .catch((err) => { })
        // 将关键词搜索的关键词清空
        this.$store.commit('getsearchInfo', '')
      } else {
        // 关键词搜索

        this.form.shop_type = this.shop_type

        await this.$api
          .EuropeKeywordSearchProduct(this.form)
          .then((result) => {
            if (result.code != 0) {
              this.goodsListIsNull = true
              return
            }
            this.slideLoading = false
            this.loading = false

            if (result.data.result) {
              this.lists.result = [...this.lists.result, ...result.data.result]
            }

            this.total = result.data.total

            this.$forceUpdate()
            if (result.data.data_type == 'goods_detail') {

              if (this.$fun.isOfficialWeb()) {
                __bl.sum('用户通过链接搜索数量统计');
              }
              this.$fun.toCommodityDetails({
                goods_id: result.data.result.goods_id,
                shop_type: result.data.result.shop_type,
                type: 'linkSearch'
              })

              return

            } else {
              if (result.data.result.length == 0 && result.data.total == 0 && this.form.page > 1) {
                this.Loadingcompleted = true
              } else if (result.data.result.length == 0 && result.data.total == 0 && this.form.page == 1) {
                this.goodsListIsNull = true
              }
            }
          })
          .catch((err) => { })
        // 将图片搜索的网址清空
        this.$store.commit('getsearchImg', '')
        this.favoriteGoodsCheckIn()
      }
    },

    getImageSliceList(region, Isnewregion) {
      if (Isnewregion) {
        this.imageRecognitionlist = []
      } else {
        if (this.imageRecognitionlist.length > 0) {
          return
        }
      }
      // 假设 res.data.result 是一个数组，包含每个区域的坐标
      const results = region // [{ left, right, top, bottom }, ...]

      // 创建图片对象
      const img = new Image()
      img.src = this.imgUrl ? this.imgUrl : this.$route.query.imgUrl // 设置图片源为 Base64
      img.crossOrigin = 'Anonymous'
      img.onload = () => {
        const croppedImages = [] // 存储切割结果

        results.forEach((resultsItem, index) => {
          for (let i in resultsItem.location) {
            resultsItem.location[i] = Number(resultsItem.location[i])
          }
          const { left, right, top, bottom } = resultsItem.location // 获取切割区域
          let width = right - left
          let height = bottom - top
          // // 验证切割区域是否合法

          const croppedCanvas = document.createElement('canvas')
          croppedCanvas.width = width
          croppedCanvas.height = height
          const croppedCtx = croppedCanvas.getContext('2d')

          // 切割指定区域
          croppedCtx.drawImage(
            img,
            left,
            top, // 原图起点
            width,
            height, // 原图宽高
            0,
            0, // 目标起点
            width,
            height // 目标宽高
          )
          // console.log(img, croppedCtx);
          // 转为 Base64 并保存到数组
          const dataURL = croppedCanvas.toDataURL('image/png')
          if (dataURL === 'data:,') {
            console.error(`区域 ${index + 1} 生成的图片为空`)
          } else {
            // 最多八张
            if (croppedImages.length >= 8) {
              return
            }
            croppedImages.push({
              url: dataURL,
              region: `${left},${right},${top},${bottom}`,
              activeChecked: index == 0 ? true : false,
              location: resultsItem.location,
            })
          }
        }) // 示例：打印所有切割后的图片
        this.imageRecognitionlist = croppedImages
      }

      img.onerror = (err) => {
        console.error('图片加载失败:', err)
        this.$message.error(this.$fanyi('图片加载失败'))
      }
    },
    // 触发文件上传
    triggerUpload() {
      this.$refs.fileInput.click() // 模拟点击 input
    },
    async handleFileUpload(event) {
      const proxy = this
      const files = event.target.files // 获取上传的文件
      if (this.fileList.length > 5 || this.fileList.length + files.length > 5) {
        proxy.$message({
          type: 'warning',
          message: proxy.$fanyi('一次最多上传六张图片'),
        })
        return
      }
      if (files.length > 0) {
        if (files.length > 5) {
          proxy.$message({
            type: 'warning',
            message: proxy.$fanyi('一次最多上传六张图片'),
          })
          return
        }

        // 开启加载状态
        this.uploadloading = true

        try {
          // 使用 Promise.all 处理所有文件的上传
          await Promise.all(
            Array.from(files).map(async (rawFile) => {
              try {
                // 校验文件大小不能超过 50MB
                if (rawFile.size / 1024 / 1024 > 50) {
                  proxy.$message.error(proxy.$fanyi('图片大小不得超过50MB'))
                  return // 跳过不符合条件的文件
                }

                // 校验文件格式是否为 JPG 或 PNG
                if (!['image/jpeg', 'image/png'].includes(rawFile.type)) {
                  proxy.$message.error(
                    proxy.$fanyi('请上传jpeg,png,jpg格式的图片')
                  )
                  return // 跳过不符合条件的文件
                }

                // 将 file 格式文件转为 canvas
                const reader = new FileReader()
                reader.readAsDataURL(rawFile)

                await new Promise((resolve, reject) => {
                  reader.onload = async (e) => {
                    try {
                      // 创建图片元素
                      const img = document.createElement('img')
                      img.src = e.target.result

                      await new Promise((resolveImg, rejectImg) => {
                        img.onload = async () => {
                          try {
                            let { width, height } = img

                            // 限制最大宽度和高度
                            const maxWidth = 800 // 最大宽度
                            const maxHeight = 800 // 最大高度

                            if (width > maxWidth || height > maxHeight) {
                              const aspectRatio = width / height
                              if (width > height) {
                                width = maxWidth
                                height = width / aspectRatio
                              } else {
                                height = maxHeight
                                width = height * aspectRatio
                              }
                            }

                            // 创建 Canvas
                            const canvas = document.createElement('canvas')
                            const context = canvas.getContext('2d')

                            // 设置 Canvas 宽高
                            canvas.width = width
                            canvas.height = height

                            // 清除 Canvas 内容
                            context.clearRect(0, 0, width, height)

                            // 绘制图片到 Canvas
                            context.drawImage(img, 0, 0, width, height)

                            // 动态调整压缩质量
                            const targetSize = 307200 // 目标大小 300KB
                            let quality = 0.6 // 初始质量（降低初始质量）

                            // 将 Canvas 转换为 Blob
                            const canvasToBlob = (quality) => {
                              return new Promise((resolve) => {
                                canvas.toBlob(
                                  (blob) => resolve(blob),
                                  'image/jpeg', // 强制输出为 JPEG 格式
                                  quality
                                )
                              })
                            }

                            let blob = await canvasToBlob(quality)

                            // 如果文件仍然过大，逐步降低质量
                            while (blob.size > targetSize && quality > 0.1) {
                              quality -= 0.05 // 减小步长
                              blob = await canvasToBlob(quality)
                            }

                            // 将 Blob 转换为 File
                            const compressedFile = new File(
                              [blob],
                              rawFile.name,
                              {
                                type: 'image/jpeg', // 强制输出为 JPEG 格式
                                lastModified: Date.now(),
                              }
                            )
                            // 上传到阿里云 OSS 或其他操作
                            const imgOBJurl = await proxy.uploadFile(compressedFile)
                            // 调用 successup 函数，处理上传后的逻辑
                            await proxy.successup(imgOBJurl)

                            resolveImg()
                          } catch (err) {
                            rejectImg(err)
                          } finally {
                            // 删除创建的图片元素
                            img.remove()
                          }
                        }

                        // 图片加载失败
                        img.onerror = () => {
                          rejectImg(new Error('图片加载失败'))
                        }
                      })
                      resolve()
                    } catch (err) {
                      reject(err)
                    }
                  }

                  // 文件读取失败
                  reader.onerror = () => {
                    reject(new Error('文件读取失败'))
                  }
                })
              } catch (err) {
                proxy.$message.error(`文件 ${rawFile.name} 处理失败:`)
              }
            })
          )
        } catch (err) {
          proxy.$message.error(proxy.$fanyi('上传过程中发生错误'))
        } finally {
          // 关闭加载状态
          proxy.uploadloading = false
        }
      }
    },
    // successup 函数
    async successup(val) {
      let that = this
      try {
        // 创建 Image 对象
        const img = new Image()
        img.crossOrigin = 'Anonymous' // 解决跨域问题

        await new Promise((resolve, reject) => {
          // 图片加载完成
          img.onload = () => {
            try {
              // 创建 Canvas
              const canvas = document.createElement('canvas')
              const ctx = canvas.getContext('2d')

              // 设置 Canvas 宽高为图片的宽高
              canvas.width = img.width
              canvas.height = img.height

              // 将图片绘制到 Canvas 上
              ctx.drawImage(img, 0, 0)

              // 将 Canvas 转换为 Base64
              const base64 = canvas.toDataURL('image/png', 0.8) // 格式和质量可调整
              const base64String = base64.split('base64,')[1] // 提取纯 Base64 数据

              // 构造上传数据
              let datas = {
                url: '/1/com.alibaba.fenxiao.crossborder/product.image.upload',
                uploadImageParam: JSON.stringify({
                  imageBase64: base64String,
                }),
              }

              // 上传图片到服务器
              that.$api
                .uploadImagePic(datas)
                .then((res) => {
                  // 往前面添加
                  // 使用 unshift 将新图片信息添加到数组开头
                  that.fileList.unshift({
                    imgUrl: val,
                    imageId: res.data.result,
                  })

                  // 存到 sessionStorage
                  sessionStorage.setItem(
                    'AmericanfileList',
                    JSON.stringify(that.fileList)
                  )
                  resolve()
                })
                .catch((error) => {
                  reject(error)
                })
            } catch (err) {
              reject(err)
            }
          }

          // 图片加载失败
          img.onerror = () => {
            reject(new Error('图片加载失败'))
          }

          // 设置图片链接
          img.src = val
        })
      } catch (error) {
        that.$message.error(that.$fanyi('处理失败'))
      }
    },
    /**
  * 上传文件
  * @param {File} file - 要上传的文件
  * @returns {Promise} - 返回一个 Promise，resolve 时返回上传结果，reject 时返回错误信息
  */
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        const url = "https://laravel.rakumart.us/api/common/uploadFile";
        // 创建 FormData 对象
        const formData = new FormData();
        formData.append("file", file);

        // 创建 XMLHttpRequest 对象
        const xhr = new XMLHttpRequest();

        // 初始化请求
        xhr.open("POST", url, true);

        // 请求完成时的回调
        xhr.onload = () => {
          if (xhr.status === 200) {
            try {
              const res = JSON.parse(xhr.responseText);
              resolve(res.data); // 上传成功，返回结果
            } catch (error) {
              reject(new Error("解析响应数据失败"));
            }
          } else {
            reject(new Error("文件上传失败"));
          }
        };

        // 请求出错时的回调
        xhr.onerror = () => {
          reject(new Error("网络错误，请检查连接"));
        };

        // 发送请求
        xhr.send(formData);
      });
    },

    deletefileItem(index) {
      // 删除
      this.fileList.splice(index, 1) // 删除指定索引的文件
      this.$forceUpdate()
      sessionStorage.setItem('AmericanfileList', JSON.stringify(this.fileList))
    },
    // 清空并且重新上柴
    clearAndReUpload() {
      this.fileList = []
      sessionStorage.setItem('AmericanfileList', JSON.stringify(this.fileList))
    },
    fileItemClick(row) {
      let newOBJ = {
        imgUrl: this.imgUrl,
        imageId: this.imageId,
      }
      // 通过 row.imageId 找到 fileList 中对应的索引
      const index = this.fileList.findIndex(
        (item) => item.imageId === row.imageId
      )
      // 如果找到对应的项
      if (index !== -1) {
        // 替换 fileList 中的该项为 newOBJ
        this.fileList[index] = newOBJ
        // 强制更新
        this.$forceUpdate()
        sessionStorage.setItem('AmericanfileList', JSON.stringify(this.fileList))
        location.href = `/commoditySearch?type=imgSearch&imgUrl=${row.imgUrl}&imageId=${row.imageId}`
      } else {
        console.warn('未找到对应的 imageId')
      }
    },
    imgClick(row) {
      this.imageRecognitionlist.forEach((item) => {
        item.activeChecked = false
      })
      row.activeChecked = true
      this.initialSelection = row.location
      this.region = row.region
      this.getData(true, 'sort', false)
    },
    // 点击框选主体
    selectionSubjectClick() {
      this.mainImageAreavisible = true
      if (!this.newinitialSelection) {
        this.newinitialSelection = this.initialSelection
      }
    },
    handselection(val) {
      this.newinitialSelection = val
    },
    // 点击确认
    ImageAreaconfirm() {
      if (this.newinitialSelection) {
        const { left, right, top, bottom } = this.newinitialSelection
        this.region = `${Number(left).toFixed(0)},${Number(right).toFixed(
          0
        )},${Number(top).toFixed(0)},${Number(bottom).toFixed(0)}`
      } else if (this.initialSelection) {
        const { left, right, top, bottom } = this.initialSelection
        this.region = `${Number(left).toFixed(0)},${Number(right).toFixed(
          0
        )},${Number(top).toFixed(0)},${Number(bottom).toFixed(0)}`
      }
      this.mainImageAreavisible = false
      this.getData(true, 'Sort', true)
    },
    Selectchange() {
      let arr = [];
      this.filter = ''
      // 1688严选
      if (this.shopchecked) {
        arr.push('jxhy')
      }
      // 严选工厂
      if (this.selectionoffactorieschecked) {
        arr.push('certifiedFactory')
      }
      // 当日出货
      if (this.Samedayshipment) {
        arr.push('shipInToday')
      }
      // 24小时出货
      if (this.Shipmentwithin24hours) {
        arr.push('shipln24Hours')
      }
      // 48小时以内出货
      if (this.Shipmentwithin48hours) {
        arr.push('shipln48Hours')
      }
      // 7天无理由退换商品
      if (this.noreasontoreturnandexchangegoodsfor7days) {
        arr.push('noReason7DReturn')
      }
      // 1件起订
      if (this.Orderonefromthebeginning) {
        arr.push('isOnePsale')
      }
      //店铺点评星级
      if (this.shopComment) {
        arr.push(this.shopComment)
      }
      if (arr.length != 0) {
        this.filter = arr.join(',')
      }
      this.form.page = 1
      this.getData(true, 'Sort')
    },
    change() {

    },
    switchSort(index) {
      this.sort = ''
      this.form.page = 1
      if (index == 6) {
        this.sortindex = !this.sortindex
        this.getData(true, 'Sort')
        this.StrictselectionArr?.forEach((item) => {
          ; (item.top = false), (item.bottom = false)
        })
        return
      }
      this.sortindex = false
      this.StrictselectionArr?.forEach((item, i) => {
        if (index == i) {
          if (i == 0) {
            item.bottom = !item.bottom
            if (item.bottom) {
              this.sort = "{'monthSold':'desc'}"
            }
            this.getData(true, 'Sort')
          }
          if (i == 1) {
            item.bottom = !item.bottom
            if (item.bottom) {
              this.sort = "{'rePurchaseRate':'desc'}"
            }
            this.getData(true, 'Sort')
          }
          if (i == 2) {
            // 说明是第一次
            if ((!item.top && !item.bottom) || (item.bottom && !item.top)) {
              item.top = true
              item.bottom = false
              // 上
              this.sort = "{'price':'asc'}"
              this.getData(true, 'Sort')
              return
            }
            if (item.top && !item.bottom) {
              item.top = false
              item.bottom = true
              // {"price":"desc"}向下
              this.sort = "{'price': 'desc'}"
              this.getData(true, 'Sort')
              return
            }
          }
        } else {
          item.bottom = false
          item.top = false
          this.sort = ''
        }
      })
    },
    toCommodityDetails(item) {
      if (this.$route.query.type == 'keywordSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过关键词搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'keywordsearch'
        })
      } else if (this.$route.query.type == 'imgSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过图片搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'imgSearch'
        })
      } else if (this.$route.query.type == 'classificationSearch') {
        if (this.$fun.isOfficialWeb()) {
          __bl.sum('通过分类搜索进入商品详情数量统计');
        }
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
          type: 'classificationSearch',
        })
      } else {
        this.$fun.toCommodityDetails({
          goods_id: item.goods_id,
          shop_type: item.shop_type,
        })
      }


    },
    //多语言导航选择
    setSnId(item, childrenIndex) {
      if ((item.active != null && item.active != childrenIndex) || item.active == null) {
        item.active = childrenIndex;
        // if (this.$fun.isOfficialWeb()) {
        //   __bl.sum('选择多语言导航次数');
        // }
      } else if (item.active != null && item.active == childrenIndex) {
        item.active = null;
      }
      let arr = [];
      this.keywordSNQuerylist?.forEach((item) => {
        if (item.active != null) {
          arr.push(item.children[item.active].id)
        }
      })
      this.snId = arr.join(';');

      this.form.page = 1;
      this.getData(true, 'Sort')
    },
    // 查看店铺是否已收藏
    favoriteGoodsCheckIn() {
      let ids = []
      if (this.lists.result?.constructor === Array) {
        this.lists.result?.forEach((goodsItem) => {
          goodsItem.goodsFavoriteStatus = false
          ids.push(goodsItem.goods_id)
        })
      }
      if (this.lists.result.constructor === Object) {
        ids.push(this.lists.result.goods_id)
      }

      this.$api
        .favoriteGoodsCheckIn({
          goods_id: JSON.stringify(ids),
        })
        .then((res) => {
          if (this.lists.result.constructor === Array) {
            res.data.forEach((resItem, resIndex) => {
              this.lists.result[resIndex].goodsFavoriteStatus =
                resItem.inFavorite
            })
          }
          if (this.lists.result.constructor === Object) {
            this.lists.result.goodsFavoriteStatus = res.data[0].inFavorite
          }
        })
    },
    handleSizeChange(val) {
      this.form.pageSize = val
      this.form.page = 1
      this.getData(true)
    },
    handleCurrentChange(val) {



      this.$store.commit('getshoplistpage', val)

      this.getData()
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
      if (numHeight > scrollHeight - 200) {
        if (!this.goodsListIsNull && this.slideLoading == false && !this.loading && !this.Loadingcompleted) {
          this.slideLoading = true
          this.form.page = this.form.page + 1

          if (!this.Loadingcompleted) {
            this.getData(false)
          }

        }
      }
    },
  },

  mounted() {
    // 当组件被挂载到 DOM 上时，添加滚动事件监听器
    window.addEventListener('scroll', this.getbottom);
    window.addEventListener('beforeunload', () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    });
  },
  beforeDestroy() {

  },
}
</script>

<style lang="scss">
.qieHuanPaiBan {
  min-width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  .PaiBanOpt {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;


    span {
      font-size: 14px;
      min-width: 100px;


    }

    img {
      width: 20px;

      height: 20px;
      margin-left: 22px;
    }
  }
}
</style>

<style lang="scss" scoped>
.ImageSelectorBox {
  .footer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    /deep/.el-button {
      border-radius: 8px;
      height: 32px;
      display: flex;
      align-items: center;

      &:first-child {
        background-color: #fff;
        font-weight: 400;
        font-size: 14px;



      }

      &:last-child {

        border-radius: 8px;
        margin-left: 20px;
      }
    }
  }
}

#CommoditySearch {
  @import '../../css/mixin';

  width: 100%;
  background-color: #f6f6f6;

  .platformListContainer {
    width: 1400px;
    margin: 0 auto;
    height: 40px;

    .platformContainer,
    .selectPlatformContainer {
      width: 128px;
      height: 40px;
      border: 1px solid #ff730b;

      color: #222222;
      justify-content: center;
      cursor: pointer;
    }

    .selectPlatformContainer {
      background: #ff730b !important;
      color: #fff !important;
    }

    .flexAndCenter:first-child {
      img {
        width: 38px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .flexAndCenter:last-child {
      img {
        width: 26px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }

  nav.strSearch {

    border-radius: 6px;
    padding: 20px 30px;
    padding-bottom: 0px;
    width: $pageWidth;
    margin: 0 auto 0px;
    position: relative;
    left: 0px;
    border-bottom: solid 1px #e2e2e2;
    background-color: #fff;
    margin-bottom: 20px;

    // 切换显示商品形式
    .crosswise {
      width: 80px;
      height: 32px;
      background: #f5f5f5;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
      cursor: pointer;

      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {

        width: 20px;
        height: 20px;
      }

      .downIcon {
        width: 12px;
        height: 12px;
        margin-left: 8px;
      }
    }

    //标题盒子

    .topCon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .keyword {
        margin: 0 10px 0 0;
      }

      .query {
        color: #608bff;
      }

      .quantity {
        margin-left: auto;

        font {
          margin: 0 5px;
        }
      }
    }

    .searchitem {
      width: 100%;
      min-height: 50px;

      border-top: 1px dashed #E2E2E2 !important;
      display: flex;
      align-items: center;
      padding: 5px 0;

      .searchitem-title {
        font-size: 14px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 14px;
        color: #222222;
        margin-right: 40px;
        line-height: 16px;
        text-align: left;
        width: 165px;
      }

      .searchitem-list {
        display: flex;

        span {
          min-height: 25px;
          text-align: center;
          display: flex;
          font-size: 14px;
          color: #333333;
          align-items: center;
          margin-right: 40px;
          cursor: pointer;
        }
      }
    }

    .spanactive {
      min-height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      padding: 8px 8px;
      text-align: center;
      border-radius: 6px;
      background: linear-gradient(90deg, #FF7E3E, #FF5010, #FE4B0F);
    }

    .Strictselection,
    .advantage {
      display: flex;
      height: 60px;
      align-items: center;
      border-bottom: 1px dashed #cccccc;

      .fornecedor {
        min-width: 153px;
        font-family: Roboto, Roboto;
        font-weight: 500;
        font-size: 16px;
        color: #222222;
        line-height: 24px;
        margin-right: 50px;
      }

      .Strictselection-ite,
      .advantage-ite {
        margin-right: 83px;
        font-weight: 400;
        text-align: center;

        font-size: 14px;
        color: #000000;
        display: flex;
        align-items: center;

        span {
          display: flex;
          margin-left: 5px;
          font-size: 14px;
        }

        .selett-title {
          margin-right: 10px;
        }

        /deep/.el-select {
          height: 28px;
          border-radius: 6px;
          width: 130px;
          font-size: 14px;
        }

        /deep/.el-input__inner {
          border-radius: 6px;
          width: 130px;
          height: 28px;
          font-size: 14px;
        }

        /deep/.el-input__suffix-inner {
          height: 28px !important;
        }

        /deep/.el-icon-arrow-up {


          &::before {
            position: absolute;
            top: 0px;
            right: 5px;
            height: 28px !important;
            line-height: 28px;

          }
        }


      }

      /deep/.el-checkbox {


        width: 15px;
        height: 15px;

        background: #ffffff;
        border-radius: 4px;

        .el-checkbox__input {
          width: 15px !important;
          border-radius: 4px;
        }

        .el-checkbox__inner {
          width: 15px !important;
          border-radius: 4px;

          height: 15px;

          &::after {
            width: 2.5px;
            left: 5px;
            height: 7px;
          }

          &:hover {
            border-color: #ff730b !important;
          }
        }

        .is-checked {
          .el-checkbox__inner {
            background-color: #ff730b;
            border-color: #ff730b;
          }
        }
      }
    }

    .Strictselection {
      border-top: 5px solid #F6F6F6;
    }

    .advantage {
      border-bottom: 5px solid #F6F6F6;
    }

    .sort {
      display: flex;
      height: 60px;
      align-items: center;

    }

    .fornecedor {
      min-width: 102px;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
      text-align: left;
      margin-right: 70px;
    }

    .sort-item {
      margin-right: 80px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #222222;
      line-height: 20px;


      &:hover {
        cursor: pointer;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
      }

      img {
        margin-left: 3px;
        width: 12px;
        height: 6px;
      }

      .pricesort {
        display: flex;
        flex-direction: column;

        padding-top: 2px;

        img {
          &:first-child {
            margin-bottom: 2px;
          }
        }
      }

      .inputbox {
        width: 115px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #999999;
        border-radius: 6px;
        padding-left: 5px;
        display: flex;
        align-items: center;

        span {
          flex: 1;
          display: flex;
          align-items: center;
        }

        input {
          border-radius: 6px;
          width: 90px;
          border: none;
        }
      }

      p {
        margin: 0 5px;
      }

      .qrbutton {
        margin-left: 20px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        height: 30px;
        background-color: #ff730b;
        border-radius: 6px;
      }
    }

    .activecolor {
      color: #ff730b !important;
      font-weight: 700;
    }

  }

  nav.imgSearch {
    width: 1400px;
    margin: auto;
    min-height: 167px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 30px;

    .topCon {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;

      .keyword {
        color: #222222;
      }

      .imgSearchRight {
        display: flex;

        .imgSearchTitle {

          display: flex;
          align-items: center;

          .imgSearchTitleleft {
            font-weight: normal;
            font-size: 14px;
            color: #222222;
            margin-right: 5px;
          }

          .imgSearchTitleright {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 14px;
            color: #999999;

            i {
              font-style: normal;
              color: #FF730B;
            }

            span {
              margin-left: 10px;
              font-weight: bold;
              font-size: 14px;
              display: flex;
              align-items: center;
              margin-left: 10px;
              color: #FF730B;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }

        // 切换显示商品形式
        .crosswise {
          width: 80px;
          height: 32px;
          background: #f5f5f5;
          border-radius: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
          cursor: pointer;

          div {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          img {

            width: 20px;
            height: 20px;
          }

          .downIcon {
            width: 12px;
            height: 12px;
            margin-left: 8px;
          }
        }
      }
    }

    .imgSearchConter {
      width: 1400px;
      margin: auto;
      height: 100%;
      display: flex;
      align-items: center;
      display: flex;

      .leftimgsearch {
        display: flex;
        align-items: center;

        .mainImageArea {
          width: 85px;
          height: 85px;

          overflow: hidden;
          flex: 0 0 88px;
          border-radius: 6px;
          position: relative;
        }

        .boxSelectionSubject {
          position: absolute;
          bottom: 0px;
          right: 0px;

          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 28px;
          height: 25px;
          background: #FE740C;
          border-radius: 6px 0px 0px 0px;

          &:hover {
            cursor: pointer;
          }

          img {
            width: 18px;
            height: 18px;
          }
        }

        .imgsBox {
          margin-left: 10px;
          display: flex;

          .img {
            display: flex;
            position: relative;
            width: 85px;
            flex: 0 0 85px;
            height: 85px;
            background: #ffffff;
            border-radius: 6px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin-right: 10px;
            padding: 3px;
            position: relative;

            border: 1px solid #E2E2E2;
            overflow: visible;

            &:hover {
              cursor: pointer;
            }
          }

          /deep/.el-image {
            display: flex;
            width: 80px;
            height: 80px;
            flex: 0 0 80px;
            justify-content: center;
            align-items: center;

            .el-image__inner {
              max-width: 100%;
              /* 图片最大宽度为容器宽度 */
              max-height: 100%;
              /* 图片最大高度为容器高度 */
              object-fit: contain;
              /* 保持图片比例，完整显示 */
            }
          }

          .imgactive {
            border: 2px solid #FF730B;

            .select {
              position: absolute;
              right: -1px;
              top: -1px;
            }
          }
        }
      }

      .uploadOnTheRightSide {
        margin-left: 10px;
        flex: 1;

        max-width: 510px;
        overflow-y: auto;

        .fileListBox {
          display: flex;
          align-items: center;

          border-left: 1px solid #e2e2e2;
          padding-left: 20px;

          .fileItem {
            width: 85px;
            flex: 0 0 85px;
            height: 85px;
            border-radius: 6px;
            position: relative;
            margin-right: 10px;
            background: #fff;
            border: 1px solid #E2E2E2;
            overflow: hidden;

            /deep/.el-image {
              display: flex;
              width: 85px;
              height: 85px;
              justify-content: center;
              align-content: center;
              border-radius: 6px;
              overflow: hidden;

              .el-image__inner {
                max-width: 100%;
                /* 图片最大宽度为容器宽度 */
                max-height: 100%;
                /* 图片最大高度为容器高度 */
                object-fit: contain;
                /* 保持图片比例，完整显示 */
              }
            }

            &:hover {
              cursor: pointer;
              border: 1px solid #FF730B;
            }

            .deleteimg {
              position: absolute;
              right: 0px;
              top: 0px;
            }
          }

          .uploadBox {
            border-radius: 6px;
            overflow: hidden;
            width: 85px;
            height: 85px;
            flex: 0 0 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border: 1px dashed #d4d4d4;
            background: #ffffff;

            .el-loading-mask {
              border-radius: 6px;
            }

            /deep/.el-icon-plus {
              margin-bottom: 5px;
              font-size: 18px;
              color: #999999;
            }

            .text {
              p {
                text-align: center;
                font-size: 12px;
                padding: 0 2px;

                margin-bottom: 2px;
                text-align: center;
                font-size: 12px;
                color: #999999;
                line-height: 12px;
                text-align: center;
              }
            }

            &:hover {
              cursor: pointer;
              background-color: #FFF8F3;
              border-radius: 6px;
              border: 1px dashed #FF730B;

              /deep/.el-icon-plus {
                margin-bottom: 5px;
                font-size: 18px;
                color: #FF730B;
              }

              .text {
                p {
                  text-align: center;
                  font-size: 12px;

                  margin-bottom: 2px;
                  text-align: center;
                  font-size: 12px;
                  color: #FF730B;
                  line-height: 12px;
                  text-align: center;
                }
              }
            }
          }
        }
      }

    }
  }



  .tiShiBox {
    font-size: 25px;
    text-align: center;
  }

  .SearchConterCrosswise {
    width: 1400px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    min-height: 280px;
    justify-content: space-between;
    position: relative;

    .SearchConterCrosswiseCon {
      box-sizing: content-box;
      width: 262px;
      overflow: hidden;
      border-radius: 6px;
      border: 1px solid #e2e2e2;

      background-color: #fff;
      margin-bottom: 20px;

      &:nth-child(5n + 1) {
        margin-right: 0;
      }

      position: relative;

      &:hover {
        .similarProducts {
          opacity: 1;
        }
      }

      &:last-child {
        margin-right: 0px;
      }

      >div {
        cursor: pointer;

        &.SearchConterCrosswiseImg {
          overflow: hidden;
          width: 262px;
          position: relative;

          .goodsImg {
            background-color: transparent;
          }

          .el-image {
            transition: 0.3s;
            width: 100%;
            min-height: 100%;
          }

          &:hover {
            .el-image {
              transform: scale(1.2, 1.2);
            }
          }
        }

        &.rests {
          box-sizing: border-box;
          padding: 10px 20px 15px 20px;
          padding-bottom: 5px;

          p {
            font-size: 12px;
            font-weight: 400;
            color: #222;

            &.goodsTitle {
              display: block;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              height: 60px;
              font-size: 14px;
              color: #222222;
              line-height: 20px;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }

            &:nth-child(2) {
              margin-top: 6px;
              height: 35px;
              display: flex;
              justify-content: space-between;
              align-items: flex-end;

              >span {
                &:first-child {
                  font-size: 16px;
                  font-weight: 600;
                  color: #ff730b;
                  line-height: 35px;
                }
              }
            }
          }
        }

      }

      .Monthlysalesvolume {
        width: 132px;
        height: 18px;
        background: #E9EEFF;
        border-radius: 2px;
        margin-bottom: 7px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        font-size: 12px;
        color: #698CFF;
        margin-left: 20px;
        display: flex;
        align-items: center;

        text-align: center;
        font-style: normal;
        padding: 0 5px;
      }

      .Repurchaserate {
        width: 172px;
        height: 18px;
        padding: 0 5px;
        margin-left: 20px;
        display: flex;
        align-items: center;

        background: #E4F5F0;
        border-radius: 2px;
        font-family: Roboto, Roboto;
        font-weight: 400;
        margin-bottom: 22px;
        font-size: 12px;
        color: #47BD9B;
        line-height: 14px;
        text-align: left;
        font-style: normal;
      }
    }


  }

  .SearchConterVertical {
    width: $pageWidth;
    min-height: 300px;
    margin: 0 auto;

    /deep/.el-image__inner {
      border-radius: 6px;
    }

    .item {
      padding: 20px;
      padding-right: 30px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 20px;
      display: flex;
      position: relative;
      cursor: pointer;

      /deep/.el-image,
      .hengxiang {
        $imgSize: 160px;
        width: $imgSize;
        height: $imgSize;
        transition: 0.3s;
        text-align: center;
        margin-right: 20px;

        i {
          font-size: $imgSize/4;
          line-height: $imgSize;
        }
      }

      .VerticalInfo {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;

        span {
          &.vGoodsTitle {
            font-size: 16px;
            width: 90%;
            height: 48px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            color: #222222;
            line-height: 24px;
          }

          &.price {
            height: 48px;
            font-size: 24px;
            font-weight: bold;
            color: #ff730b;
            line-height: 48px;
          }


        }

        .xiaoliag {
          height: 20px;
          font-size: 14px;
          color: #999999;
          line-height: 20px;
          position: absolute;
          left: 200px;
          bottom: 45px;

          P {
            font-size: 14px;
            color: #999999;
            margin-bottom: 3px;
            line-height: 20px;
          }
        }
      }

      .collect {
        width: 56px;
        height: 56px;
        background: #f5f5f5;
        border-radius: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);

        >div {
          width: 20px;
          height: 20px;
        }

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

/deep/.el-image {
  text-align: center;

  i {
    font-size: 40px;

    line-height: 330px;
  }
}



.similarProducts {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  transition: 0.3s;
  background-color: white;
  border: solid 1px #ffa018;
  color: #ffa018;
  font-size: 14px;
  padding: 4px 12px;
  z-index: 3;
  border-radius: 5px;

  &:hover {
    color: white;
    background-color: #ffa018;
    opacity: 1;
  }
}

.keywordLoadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1400px;
  height: 180px;
  padding: 20px;

  .flexAndCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  img {
    width: 40px;
  }

  div {
    font-size: 14px;
  }
}

.goodsListIsNull {
  width: 1400px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 45px;
  font-weight: bold;
  color: #ccc;
  height: 400px;
}

/deep/.el-loading-mask {
  z-index: 5;
}

/deep/.el-image.biggoodsImg {
  $imgSize: 240px;
  width: $imgSize;
  height: $imgSize;
  transition: 0.3s;
  text-align: center;

  i {
    font-size: $imgSize/4;
    line-height: $imgSize;
  }
}

.allpag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px 0;

  .el-pagination {
    display: flex;
    align-items: center;
  }

  >a {
    display: inline-block;
    border: 1px solid #dcdfe6;
    height: 28px;
    // background-color: white;
    text-align: center;
    padding: 0 10px;
    font-size: 13px;
    line-height: 28px;
    border-radius: 0;
  }

  /deep/.btn-prev {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    padding: 0;
    margin-right: 30px;

    .el-icon {
      font-size: 15px;
    }
  }

  /deep/.btn-next {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    padding: 0;
    margin-left: 30px;

    .el-icon {
      font-size: 15px;
    }
  }

  /* 分页被选中的页码样式 */
  /deep/ .el-pager li {
    margin: 0 10px;
    // border-radius: 0;
    min-width: 20px !important;
    font-size: 14px;
    height: 30px;
    color: #999999;
    background-color: #f6f6f6;
    // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

    &:hover {
      color: #ff730b;
    }

    &.active {
      color: #ff730b;
      border-bottom: 2px solid #ff730b;
      min-width: 20px;
    }
  }
}
</style>
